import $ from "jquery";

export default function () {

  function initEvents() {
    console.log('initEvents');

    bindPagination();

    function bindPagination() {
      $(".page").on("click", function (e) {
        e.preventDefault();
        var targetPage = $(this).data("page");
        selectEvents(targetPage);
      });

      $('#itemsPerPage').on("change", function () {
        selectEvents();
      });
    }

    $(".event-search select").on("change", function (e) {
      e.preventDefault();
      selectEvents();
    });

    $(".event-search #selectDateFrom").on("change", function (e) {
      e.preventDefault();
      selectEvents();
    });

    $(".event-search #selectDateTo").on("change", function (e) {
      e.preventDefault();
      selectEvents();
    });

    function selectEvents(page) {
      if (typeof page === "undefined" || page === null) {
        page = 1;
      }

      $.ajax({
        url: "/api/sitecore/events/EventsSearch",
        type: "POST",
        data: {
          dateFrom: $("#selectDateFrom").val(),
          dateTo: $("#selectDateTo").val(),
          topic: $("#selectTopic").val(),
          location: $("#selectLocations").val(),
          page: page,
          pageSize: $("#itemsPerPage").val()
        },
        context: this,
        success: function (data) {
          var resultHtml = $.parseHTML(data.trim());

          if (resultHtml !== null) {
            $('#search-results-container').html(resultHtml);
          }

          $('#resultCount').text("(" + $('#maxResults').val() + ")");

          bindPagination();

          var offset = $("#search-results-container").offset().top - 150;
          $("html, body").animate({ scrollTop: offset }, 500);
        },
        error: function () {
          $("#eventPagePagination").html("");
        }
      });
    }
  }

  if ($(".event-search").length) {
    initEvents();
  }
}
