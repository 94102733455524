import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

export default function () {

  function initResearcherFinder() {
    console.log('initResearcherFinder');

    var searchCategory = "Researcher";
    var $datasource = $('#hdnDatasource');
    var searchResultsUrl = $('#hdnResultsUrl').val();
    var $clearBtn = $('.btn-clear');
    var $searchBtn = $('.btn-researcher-finder-search');
    var datasource = $datasource.val();
    var $searchInput = $('#find-a-researcher');

    bindPagination();
    
    function bindPagination() {
      $(".page").on("click", function (e) {
        e.preventDefault();
        var targetPage = $(this).data("page");
        var sort = $("#orderBy").val();
        selectResearchers(targetPage, null, sort);
      });

      $('#itemsPerPage').on("change", function () {
        selectResearchers();
      });
    }

    $(".researchers-search-filter select").on("change", function (e) {
      e.preventDefault();
      selectResearchers(null, true);
    });

    $('.researchers-results-filter-link[data-sort]').on("click", function (e) {
      e.preventDefault();
      var sort = $(this).data("sort");
      if (sort !== "") {
        $("#orderBy").val(sort);
        $('.researchers-results-filter-link[data-sort]').removeClass("active");
        $(this).addClass("active");
        selectResearchers(1, false, sort);
      }
    });

    function selectResearchers(page, triggerGtmEvent, sort) {
      if (typeof page === "undefined" || page === null) {
        page = 1;
      }

      var orderBy = sort;
      if (typeof orderBy === "undefined" || orderBy === null) {
        orderBy = "";
      }

      // set default value for this optional parameter
      triggerGtmEvent = triggerGtmEvent === true;

      if (triggerGtmEvent) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          "event": "refineResearchers",
          "interest": $("#selectInterests option:selected").text(),
          "campus": $("#selectCampus option:selected").text(),
          "researchProgram": $("#selectResearchProgram option:selected").text(),
          "lastName": $("#selectLastName option:selected").text()
        });
      }
      let spinner = '<li><p style="text-align: center;"><img src="/ui/images/spinner.gif" alt="Waiting Spinner"></p></li>';
      $("#search-results-list li").remove();
      $("#search-results-list").append(spinner);

      $.ajax({
        url: "/api/sitecore/FindAResearcher/SearchResearcherResults",
        type: "POST",
        data: {
          all: $("#hdnAll").val(),
          query: $("#hdnQuery").val(),
          interest: $("#selectInterests").val(),
          campus: $("#selectCampus").val(),
          researchProgram: $("#selectResearchProgram").val(),
          lastName: $("#selectLastName").val(),
          datasource: $datasource.val(),
          page: page,
          pageSize: $("#itemsPerPage").val(),
          orderBy: orderBy
        },
        context: this,
        success: function (data) {
          var resultHtml = $.parseHTML(data.trim());

          // Set the researcher results into proper location in dom
          var resultsDiv = $(resultHtml).find("#divResearchers");
          if (resultsDiv.length) {
            $("#search-results-list").html(resultsDiv.html());
            $("#resultCount").text("(" + $("#maxResults").val() + ")");
            $("#orderBy").val(orderBy);
          }

          // Set pagination into proper location in dom
          var paginationDiv = $(resultHtml).find("#divPagination");
          if (paginationDiv.length) {
            $(".page-pagination").html(paginationDiv.html());
          }

          // Have to rebind pagination after adding new elements
          bindPagination();

          var offset = $("#search-results-list").offset().top - 150;
          $("html, body").animate({ scrollTop: offset }, 500);
        },
        error: function (data) {
          console.log("error", data);
        }
      });
    }

    if ($(".researchers-search").length) {
      var offset = $("#search-results-list").offset().top - 150;
      $("html, body").animate({ scrollTop: offset }, 500);
    }

    var allResearchers;
    var researchers = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/FindAResearcher/SearchResearchers?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allResearchers = response.Researchers;
          buildModal(allResearchers, "researchers");
          return response.Researchers;
        }
      }
    });

    var allResearchPrograms;
    var researchPrograms = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/FindAResearcher/SearchResearchers?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allResearchPrograms = response.ResearchPrograms;
          buildModal(allResearchPrograms, "researcherPrograms");
          return response.ResearchPrograms;
        }
      }
    });

    var allCampuses;
    var campuses = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/FindAResearcher/SearchResearchers?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allCampuses = response.Campuses;
          buildModal(allCampuses, "campuses");
          return response.Campuses;
        }
      }
    });

    var allInterests;
    var interests = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/FindAResearcher/SearchResearchers?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allInterests = response.Interests;
          buildModal(allInterests, "campuses");
          return response.Interests;
        }
      }
    });

    function buildModal(items, identifier) {
      var targetEle = $('#' + identifier + 'Modal').find(".modal-body");
      targetEle.empty(); // clear previous results

      var numOfLists = 3;

      var cleanList = items.filter(function (item) {
        return item.Name !== "View All";
      });

      var lists = [], index = 0;
      // for each column (or list) - l indicates the number of lists left to render
      for (var l = numOfLists; l > 0; l--) {
        // Figure out the length of the next column by dividing by the # of columns left
        var length = Math.floor(((cleanList.length - index) + (l - 1)) / l);
        // Initiate a blank list
        var list = $('<ul class="modal-list"/>');
        // Fill out the list
        for (var i = 0; i < length; i++) {
          // Meanwhile, track the index of where we are in the main list
          var item = cleanList[index++];
          list.append($('<li class="modal-list-item"><a href="' +
            item.Url + '" class="modal-list-link">' +
            item.Name + '</a></li>'));
        }
        lists.push(list);
      }

      var row = $('<div class="row"/>');
      lists.forEach(function (list) {
        row.append($('<div class="col-lg-4"/>').append(list));
      });
      targetEle.append(row);
    }

    $('.modal').on('hidden.bs.modal', function () {
      $searchInput.focus();
    });

    $searchInput.typeahead({
      highlight: true,
      minLength: 3,
      hint: false,
      classNames: {
        suggestion: 'search-autocomplete-item',
        menu: 'search-autocomplete',
        dataset: 'search-autocomplete-listing'
      }
    },
    {
      name: "researchers",
      display: "Name",
      source: researchers,
      limit: 6,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Researchers</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Researchers</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "researchPrograms",
      display: "Name",
      source: researchPrograms,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Research Programs</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Research Programs</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "campuses",
      display: "Name",
      source: campuses,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Campuses</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Campuses</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "interests",
      display: "Name",
      source: interests,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Interests</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Interests</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    });

    $searchInput.on('keyup', function () {
      if ($searchInput.val() !== "") {
        $clearBtn.addClass("active");
      } else {
        $clearBtn.removeClass("active");
      }
    });

    $searchInput.on('keypress', function (event) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13') {
        var keyword = $searchInput.val();
        if (keyword !== "") {
          window.location.href = searchResultsUrl + '?q=' + keyword + '&category=' + encodeURIComponent(searchCategory);
        }
      }

      event.stopPropagation();
    });

    $searchBtn.on('click', function () {
      var keyword = $searchInput.val();
      if (keyword !== "") {
        window.location.href = searchResultsUrl + '?q=' + keyword + '&category=' + encodeURIComponent(searchCategory);
      }
    });

    $clearBtn.on('click', function () {
      $clearBtn.removeClass("active");
      $searchInput.focus();
      $searchInput.val(''); // Clear input value
      $searchInput.typeahead('val', ''); // Clear typeahead value
    });
  }

  if ($("#find-a-researcher").length) {
    initResearcherFinder();
  }
}
