//dependencies
import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");
var searchCategory = "Global";
var searchUrl = "/search";

function updateQueryString(key, value, url) {
  if (!url) url = window.location.href;
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
    hash;

  if (re.test(url)) {
    if (typeof value !== 'undefined' && value !== null) {
      value = encodeURIComponent(value);
      return url.replace(re, '$1' + key + "=" + value + '$2$3');
    } else {
      hash = url.split('#');
      url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
      if (typeof hash[1] !== 'undefined' && hash[1] !== null)
        url += '#' + hash[1];
      return url;
    }
  }
  else {
    if (typeof value !== 'undefined' && value !== null) {
      value = encodeURIComponent(value);
      var separator = url.indexOf('?') !== -1 ? '&' : '?';
      hash = url.split('#');
      url = hash[0] + separator + key + '=' + value;
      if (typeof hash[1] !== 'undefined' && hash[1] !== null)
        url += '#' + hash[1];
      return url;
    }
    else
      return url;
  }
}

function siteSearch(queryTerm) {
  if (!queryTerm) { return; }
  var url = searchUrl + window.location.search;
  window.location.href = updateQueryString("q", queryTerm, url) + '&category=' + encodeURIComponent(searchCategory);
}

export default function () {

  function initPagination() {
    // Do not execute on Phys finder, Clinical Trials, Researcher finder, or Events page because they use AJAX
    if ($(".physician-finder-search").length === 0 && $(".event-search").length === 0 && $(".clinical-trials-search").length === 0 && $("#find-a-researcher").length === 0) {
      $(".page").on("click", function (e) {
        e.preventDefault();
        var targetPage = $(this).data("page");
        if (targetPage === 1) targetPage = null; // clear page from querystring if 1
        var url = updateQueryString("Page", targetPage);
        window.location = url;
      });

      $('#itemsPerPage').on("change", function () {
        var url = updateQueryString("PageSize", $(this).val());
        window.location = updateQueryString("Page", null, url); // reset page when changing pagesize
      });
    }
  }

  function initNews() {
    $('#btnApplyFilters').on("click", function (e) {
      e.preventDefault();

      var url = updateQueryString("Page", null); // Reset current page index

      var topic = $("#selectTopic").val();
      if (topic !== '') {
        if (topic === "All") {
          url = updateQueryString("Topic", null, url);
        } else {
          url = updateQueryString("Topic", topic, url);
        }
      }

      var dateBy = $('#selectDateBy').val();
      if (dateBy !== '') {
        if (dateBy === "All") {
          url = updateQueryString("Date", null, url); // Reset all date filters 
          url = updateQueryString("DateFrom", null, url);
          url = updateQueryString("DateTo", null, url);
        } else {
          url = updateQueryString("Date", dateBy, url);
        }
      }

      var dateFrom = $('#selectDateFrom').val();
      if (dateFrom !== '') {
        url = updateQueryString("DateFrom", dateFrom, url);
      } else {
        url = updateQueryString("DateFrom", null, url);
      }

      var dateTo = $('#selectDateTo').val();
      if (dateTo !== '') {
        url = updateQueryString("DateTo", dateTo, url);
      } else {
        url = updateQueryString("DateTo", null, url);
      }

      window.location.href = url;
    });
  }

  function initSiteSearch() {
    if ($('#search-input').length) {
      // Typeahead
      var doctorsCount;
      var doctors = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            doctorsCount = response.Doctors.length;
            return response.Doctors;
          }
        }
      });

      var specialtiesCount;
      var specialties = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            specialtiesCount = response.Specialties.length;
            return response.Specialties;
          }
        }
      });

      var locationsCount;
      var locations = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            locationsCount = response.Locations.length;
            return response.Locations;
          }
        }
      });

      var moreResultsCount;
      var moreResults = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            moreResultsCount = response.MoreResults.length;
            return response.MoreResults;
          }
        }
      });

      var searchInput = $("#search-input");

      searchInput.typeahead({
        highlight: true,
        minLength: 3,
        hint: false,
        classNames: {
          suggestion: 'search-autocomplete-item',
          menu: 'search-autocomplete',
          dataset: 'search-autocomplete-listing'
        }
      },
      {
        name: "doctors",
        display: "DoctorName",
        source: doctors,
        limit: 3,
        templates: {
          header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Doctors</h2></div>",
          suggestion: function suggestion(data) {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'><span class='search-autocomplete-profile'><img src='" +
              data.ProfileImage + "' alt='Profile'></span>" + data.Name + "</a></div>";
          },
          footer: function footer(data) {
            if (doctorsCount > 3) {
              return "<div class='search-autocomplete-link search-autocomplete-item tt-selectable'><a href='" + searchUrl + "?q=" + data.query + '&category=' + encodeURIComponent(searchCategory) + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "";
            }
          }
        }
      },
      {
        name: "specialties",
        display: "Name",
        source: specialties,
        limit: 3,
        templates: {
          header:
            "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Specialty</h2></div>",
          suggestion: function suggestion(data) {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          },
          footer: function footer(data) {
            if (specialtiesCount > 3) {
              return "<div class='search-autocomplete-link search-autocomplete-item tt-selectable'><a href='" + searchUrl + "?q="+ data.query + '&category=' + encodeURIComponent(searchCategory) + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "";
            }
          }
        }
      },
      {
        name: "locations",
        display: "Name",
        source: locations,
        limit: 3,
        templates: {
          header:
            "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Locations</h2></div>",
          suggestion: function suggestion(data) {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          },
          footer: function footer(data) {
            if (locationsCount > 3) {
              return "<div class='search-autocomplete-link search-autocomplete-item tt-selectable'><a href='" + searchUrl + "?q=" + data.query + '&category=' + encodeURIComponent(searchCategory) + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "";
            }
          }
        }
      },
      {
        name: "moreResults",
        display: "Name",
        source: moreResults,
        limit: 3,
        templates: {
          header:
            "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>More Results</h2></div>",
          suggestion: function suggestion(data) {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          },
          footer: function footer(data) {
            if (moreResultsCount > 3) {
              return "<div class='search-autocomplete-link search-autocomplete-item tt-selectable'><a href='" + searchUrl + "?q=" + data.query + '&category=' + encodeURIComponent(searchCategory) + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "";
            }
          }
        }
      });

      // Append the Show All button (dumb hack - no better way apparently)
      if (searchInput.length) {
        var menu = searchInput.siblings('.search-autocomplete');
        if (menu.length) {
          menu.append(
            "<div role='presentation' class='search-autocomplete-listing search-autocomplete-listing-showAll'><div class='search-autocomplete-show-all'><a href='#' class='btn-link btn-link-inverse btn-arrow btn-arrow-next'>Show All Results</a></div></div>");
        }

        $('.search-autocomplete-show-all > a').on('click', function (e) {
          e.preventDefault();

          var url = searchUrl;
          if (searchInput.val() !== "") {
            url = url + "?q=" + searchInput.val() + '&category=' + encodeURIComponent(searchCategory);
          }
          window.location.href = url;
        });
      }

      $("#BestMatch").attr("href", updateQueryString("SortBy", "BestMatch"));
      $("#AZSort").attr("href", updateQueryString("SortBy", "AZ"));
      $("#ZASort").attr("href", updateQueryString("SortBy", "ZA"));
      $("#MostRecent").attr("href", updateQueryString("SortBy", "Recent"));

      $('#selectType').on('select2:select', function (e) {
        var selected = e.params.data.id;

        var url = updateQueryString("Page", null); // clear page
        if (selected === "All") {
          window.location = updateQueryString("Filter", null, updateQueryString("MaxResults", null, url)); // clear maxresults string
        } else {
          window.location = updateQueryString("Filter", selected, updateQueryString("MaxResults", $('#hdnMaxResults').val(), url)); // pass maxresults into querystring
        }
      });

      $('#btnSearch').on("click", function (e) {
        e.preventDefault();
        siteSearch(searchInput.val());
      });

      var $clearBtn = $('#btnClearSearch');

      var $selectedItem;

      searchInput.on('keyup', function (e) {

        // If keys pressed are up or down. Store ttcursor.
        if (e.which == 38 || e.which == 40) {
          $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
        }

        // If enter has been clicked, use the item in $selected item to perform the click.
        if (e.which == 13) {
          if ($selectedItem) {
            $selectedItem.click();
          }
        }

        if (searchInput.val() !== "") {
          $clearBtn.addClass("active");
        } else {
          $clearBtn.removeClass("active");
        }
      });

      $clearBtn.on('click', function () {
        $clearBtn.removeClass("active");
        searchInput.focus();
        searchInput.val(''); // Clear input value
        searchInput.typeahead('val', ''); // Clear typeahead value
      });

      searchInput.keypress(function (e) {
        if (e.which === 13) {
          siteSearch(searchInput.val());
        }
      });
    }
  }

  function initMobileSiteSearch() {
    var $mobileSearchInput = $('#searchMobile');
    if ($mobileSearchInput.length != 0) {
      $mobileSearchInput.on('keyup', function (e) {
        if (e.which == 13) {
          siteSearch($mobileSearchInput.val());
        }
      });
    }
  }

  initSiteSearch();
  initMobileSiteSearch();
  initNews();
  initPagination();
}
