import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

export default function () {

  function initClinicalTrials() {
    console.log('initClinicalTrials');

    var searchCategory = "Clinical Trials";
    var $datasource = $('#hdnDatasource');
    var searchResultsUrl = $('#hdnResultsUrl').val();
    var $clearBtn = $('.btn-clear');
    var $searchBtn = $('.btn-clinical-trials-search');
    var datasource = $datasource.val();
    var $searchInput = $("#find-a-clinical-trial");

    bindPagination();
    bindClinicalTrialDetailLinks();

    function bindPagination() {
      $(".page").on("click", function (e) {
        e.preventDefault();
        var targetPage = $(this).data("page");
        var sort = $("#orderBy").val();
        selectClinicalTrials(targetPage, null, sort);
      });

      $('#itemsPerPage').on("change", function () {
        selectClinicalTrials();
      });
    }

    function bindClinicalTrialDetailLinks() {
      $(".clinical-trial-detail-link").on("click", function (e) {
        e.preventDefault();
        var url = getClinicalTrialsSearchUrl();

        window.location.href = url;

      });
    }

    // Build full URL including current clinical trials finder filters in query string
    function getClinicalTrialsSearchUrl() {
      let clinicalTrialFinderUrl = window.location.pathname;

      var primaryFilterType = $('#hdnPrimaryFilterType').val();
      if (primaryFilterType !== '') {
        var appliedFilter = $('#hdnAppliedFilter').val();
        if (appliedFilter !== '') {
          clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, primaryFilterType, appliedFilter);
        }
      }

      var cancerType = $("#selectCancerTypes").val();
      if (cancerType !== '' && cancerType !== 'All') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "cancerType", cancerType);
      }

      var studyType = $("#selectStudyTypes").val();
      if (studyType !== '') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "studyType", studyType);
      }

      var phase = $("input[name='choicePhase']:checked").val();
      if (phase !== '' && phase !== 'All') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "phase", phase);
      }

      var principalInvestigator = $("#selectPrincipalInvestigators").val();
      if (principalInvestigator !== '' && principalInvestigator !== 'All') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "principalInvestigator", principalInvestigator);
      }

      var query = $("#hdnQuery").val();
      if (query !== '') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "q", query);
      }

      var pageSize = $("#itemsPerPage").val();
      if (pageSize !== '') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "pageSize", pageSize);
      }
      var orderBy = $("#orderBy").val();
      if (orderBy !== '') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "orderBy", orderBy);
      }
      var randomSeed = $("#hdnRandomSeed").val();
      if (randomSeed !== '') {
        clinicalTrialFinderUrl = addQueryStringParameter(clinicalTrialFinderUrl, "randomSeed", randomSeed);
      }

      return clinicalTrialFinderUrl;
    }

    // Add a query string parameter
    function addQueryStringParameter(url, key, value) {
      url += url.indexOf("?") > 0 ? "&" : "?";
      url += key + "=" + encodeURIComponent(value);
      return url;
    }

    $(".clinical-trials-search select").on("change", function (e) {
      e.preventDefault();
      selectClinicalTrials(null, true);
    });

    $(".clinical-trials-search input[name=choicePhase]").on("change", function (e) {
      e.preventDefault();
      selectClinicalTrials(null, true);
    });

    $('.clinical-trials-search .results-filter-link[data-sort]').on("click", function (e) {
      e.preventDefault();
      var sort = $(this).data("sort");
      if (sort !== "") {
        $("#orderBy").val(sort);
        $('.clinical-trials-search .results-filter-link[data-sort]').removeClass("active");
        $(this).addClass("active");
        selectClinicalTrials(1, false, sort);
      }
    });

    function selectClinicalTrials(page, triggerGtmEvent, sort) {
      if (typeof page === "undefined" || page === null) {
        page = 1;
      }

      var orderBy = sort;
      if (typeof orderBy === "undefined" || orderBy === null) {
        orderBy = "";
      }

      // set default value for this optional parameter
      triggerGtmEvent = triggerGtmEvent === true;

      if (triggerGtmEvent) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          "event": "refineClinicalTrials",
          "cancerType": $("#selectCancerTypes option:selected").text(),
          "studyType": $("#selectStudyTypes option:selected").text(),
          "principalInvestigator": $("#selectPrincipalInvestigators option:selected").text(),
          "phase": $("input[name='choicePhase']:checked").data("radiotext")
        });
      }
      let spinner = '<li><p style="text-align: center;"><img src="/ui/images/spinner.gif" alt="Waiting Spinner"></p></li>';
      $("#search-results-list li").remove();
      $("#search-results-list").append(spinner);

      $.ajax({
        url: "/api/sitecore/ClinicalTrials/SearchClinicalTrialResults",
        type: "POST",
        data: {
          datasource: $datasource.val(),
          cancerType: $("#selectCancerTypes").val(),
          studyType: $("#selectStudyTypes").val(),
          principalInvestigator: $("#selectPrincipalInvestigators").val(),
          query: $("#hdnQuery").val(),
          nctNumber: $("#hdnNCT").val(),
          phase: $("input[name='choicePhase']:checked").val(),
          pageSize: $("#itemsPerPage").val(),
          orderBy: orderBy,
          page: page,
          randomSeed: $("#hdnRandomSeed").val()
        },
        context: this,
        success: function (data) {
          var resultHtml = $.parseHTML(data.trim());

          // Set the physician results into proper location in dom
          var physDiv = $(resultHtml).find("#divClinicalTrials");
          if (physDiv.length) {
            $("#search-results-list").html(physDiv.html());
            $('#resultCount').text("(" + $('#maxResults').val() + ")");
            $("#orderBy").val(orderBy);
          }

          // Set pagination into proper location in dom
          var paginationDiv = $(resultHtml).find("#divPagination");
          if (paginationDiv.length) {
            $(".page-pagination").html(paginationDiv.html());
          }

          // Sets the sort button active, mostly applies for setting distance sort after passing in a zip
          $('.clinical-trials-search .results-filter-link[data-sort]').removeClass("active");
          $('.clinical-trials-search .results-filter-link[data-sort="' + $("#orderBy").val() + '"]').addClass("active");

          // Have to rebind pagination after adding new elements
          bindPagination();

          var offset = $("#search-results-list").offset().top - 150;
          $("html, body").animate({ scrollTop: offset }, 500);
        },
        error: function (data) {
          console.log("error", data);
        }
      });
    }

    if ($(".clinical-trials-search").length) {
      var offset = $("#search-results-list").offset().top - 150;
      $("html, body").animate({ scrollTop: offset }, 500);
    }

    var allCancerTypes;
    var cancerTypes = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/ClinicalTrials/SearchClinicalTrials?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allCancerTypes = response.CancerTypes;
          buildModal(allCancerTypes, "cancerTypes");
          return response.CancerTypes;
        }
      }
    });

    var allStudyTypes;
    var studyTypes = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/ClinicalTrials/SearchClinicalTrials?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allStudyTypes = response.StudyTypes;
          buildModal(allStudyTypes, "studyTypes");
          return response.StudyTypes;
        }
      }
    });
    var allPrincipalInvestigators;
    var principalInvestigators = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/ClinicalTrials/SearchClinicalTrials?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allPrincipalInvestigators = response.PrincipalInvestigators;
          buildModal(allPrincipalInvestigators, "principalInvestigators");
          return response.PrincipalInvestigators;
        }
      }
    });

    var clinicalTrials = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/ClinicalTrials/SearchClinicalTrials?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          return response.ClinicalTrials;
        }
      }
    });

    function buildModal(items, identifier) {
      var targetEle = $('#' + identifier + 'Modal').find(".modal-body");
      targetEle.empty(); // clear previous results

      var numOfLists = 3;

      var cleanList = items.filter(function (item) {
        return item.Name !== "View All";
      });

      var lists = [], index = 0;
      // for each column (or list) - l indicates the number of lists left to render
      for (var l = numOfLists; l > 0; l--) {
        // Figure out the length of the next column by dividing by the # of columns left
        var length = Math.floor(((cleanList.length - index) + (l - 1)) / l);
        // Initiate a blank list
        var list = $('<ul class="modal-list"/>');
        // Fill out the list
        for (var i = 0; i < length; i++) {
          // Meanwhile, track the index of where we are in the main list
          var item = cleanList[index++];
          list.append($('<li class="modal-list-item"><a href="' +
            item.Url + '" class="modal-list-link">' +
            item.Name + '</a></li>'));
        }
        lists.push(list);
      }

      var row = $('<div class="row"/>');
      lists.forEach(function (list) {
        row.append($('<div class="col-lg-4"/>').append(list));
      });
      targetEle.append(row);
    }

    $('.modal').on('hidden.bs.modal', function () {
      $searchInput.focus();
    });

    $searchInput.typeahead({
      highlight: true,
      minLength: 3,
      hint: false,
      classNames: {
        suggestion: 'search-autocomplete-item',
        menu: 'search-autocomplete',
        dataset: 'search-autocomplete-listing'
      }
    },
    {
      name: "cancerTypes",
      display: "Name",
      source: cancerTypes,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Cancer Types</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#cancerTypesModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Cancer Types</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "&q=" + $searchInput.val() + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "studyTypes",
      display: "Name",
      source: studyTypes,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Study Types</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#studyTypesModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Study Types</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "&q=" + $searchInput.val() + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "principalInvestigators",
      display: "Name",
      source: principalInvestigators,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Principal Investigators</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#principalInvestigatorsModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Principal Investigators</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "&q=" + $searchInput.val() + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "clinicalTrials",
      display: "Name",
      source: clinicalTrials,
      limit: 6,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>NCT Number</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Clinical Trials</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "&q=" + $searchInput.val() + "' class='search-autocomplete-link'>" + ((data.ImageUrl !== null) ? "<span class='search-autocomplete-profile'></span>" : "") + data.Name + "</a></div>";
          }
        }
      }
    });

    var $selectedItem;

    $searchInput.on('keyup', function (e) {

      // If keys pressed are up or down. Store ttcursor.
      if (e.which == 38 || e.which == 40) {
        $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
      }

      // If enter has been clicked, use the item in $selected item to perform the click.
      if (e.which == 13) {
        if ($selectedItem) {
          $selectedItem.click();
        }
      }

      else {
        if ($searchInput.val() !== "") {
          if ($searchInput.val().length >= 3) {
            $clearBtn.addClass("active");
          }
        } else {
          $clearBtn.removeClass("active");
        }
      }
    });

    $searchInput.on('keypress', function (event) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13') {
        var keyword = $searchInput.val();
        if (keyword !== "") {
          if (keyword.length >= 3) {
            window.location.href = searchResultsUrl + '?q=' + keyword + '&category=' + encodeURIComponent(searchCategory);
          }
        }
      }
    
      event.stopPropagation();
    });

    $searchBtn.on('click', function () {
      var keyword = $searchInput.val();
      if (keyword !== "") {
        if (keyword.length >= 3) {
          window.location.href = searchResultsUrl + '?q=' + keyword + '&category=' + encodeURIComponent(searchCategory);
        }
      }
    });

    $clearBtn.on('click', function () {
      $clearBtn.removeClass("active");
      $searchInput.focus();
      $searchInput.val(''); // Clear input value
      $searchInput.typeahead('val', ''); // Clear typeahead value
    });
  }

  if ($("#find-a-clinical-trial").length) {
    initClinicalTrials();
  }
}
