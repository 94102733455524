//dependencies
import $ from "jquery";
import 'slick-carousel';

export default function () {

  function initCarousel() {

    const $carousel = $(".carousel");                           // generic carousel
    const $carouselNewsEvents = $(".carousel-news-events");     // carousel news and events
    const $carouselCompare = $(".carousel-compare");            // carousel comparison
    const $carouselReviews = $(".carousel-reviews");            // carousel reviews
    const $carouselTwoCol = $(".carousel-two-column");          // carousel reviews
    const $carouselFourCol = $(".carousel-four-column-cards");   // carousel four column cards

    // Configuration options for the carousel - http://kenwheeler.github.io/slick/

    // Look to see if .carousel is on page...
    if ($carousel.length) {

      $carouselNewsEvents.each(function () {
        const $this = $(this);
        const $carousel = $this.find(".carousel");

        // Callback function on carousel Initialize complete - must appear before carousel initialization
        $carousel.on("init", function () {
          $carousel.removeClass("no-js");
        });

        // Determine if homepage or not.
        let $count = 3;
        //when we have a lot of items, the carousel is incredibly crowded and looks horrible.
        if ($carouselNewsEvents.hasClass("home-carousel-news-events")) {
          $count = 4;
        }

        // Settings
        $carousel.slick({
          arrows: true,
          prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous"><span class="sr-only">Previous</span></a>',
          nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next"><span class="sr-only">Next</span></a>',
          infinite: true,
          dots: true,
          variableWidth: false,
          adaptiveHeight: false,
          slidesToShow: $count,
          slidesToScroll: 1,
          draggable: true,
          rows: 0,
          //appendArrows: $this.find($arrowContainer),

          responsive: [
            // {
            //   breakpoint: 1200,
            //   settings: {
            //     arrows: true,
            //     variableWidth: true,
            //     slidesToShow: 3,
            //     slidesToScroll: 1
            //   }
            // },
            {
              breakpoint: 1025,
              settings: {
                arrows: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                dots: false,
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false
              }
            }
          ]
        });
      });

      $carouselCompare.each(function () {
        const $this = $(this);
        const $carousel = $this.find(".carousel");

        // Callback function on carousel Initialize complete - must appear before carousel initialization
        $carousel.on("init", function () {
          $carousel.removeClass("no-js");
        });

        // Determine if homepage or not.
        let $count = 1;

        // Settings
        $carousel.slick({
          arrows: true,
          prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous"><span class="sr-only">Previous</span></a>',
          nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next"><span class="sr-only">Next</span></a>',
          infinite: false,
          dots: true,
          variableWidth: false,
          adaptiveHeight: false,
          slidesToShow: $count,
          slidesToScroll: 1,
          draggable: true,
          rows: 0,
          //appendArrows: $this.find($arrowContainer),

          responsive: [
            {
              breakpoint: 1200,
              settings: {
                variableWidth: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                dots: true,
                arrows: true,
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      });

      // Two Column Carousel
      $carouselTwoCol.each(function () {
        const $this = $(this);
        const $carousel = $this.find(".carousel");

        // Callback function on carousel Initialize complete - must appear before carousel initialization
        $carousel.on("init", function () {
          $carousel.removeClass("no-js");
        });

        // Determine if homepage or not.
        let $count = 1;

        // Settings
        $carousel.slick({
          arrows: true,
          prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous"><span class="sr-only">Previous</span></a>',
          nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next"><span class="sr-only">Next</span></a>',
          infinite: true,
          dots: true,
          variableWidth: false,
          adaptiveHeight: false,
          slidesToShow: $count,
          slidesToScroll: 1,
          draggable: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: true
              }
            }
          ]
        });
      });

      // Carousel Reviews
      $carouselReviews.each(function () {
        const $this = $(this);
        const $carousel = $this.find(".carousel");

        // Callback function on carousel Initialize complete - must appear before carousel initialization
        $carousel.on("init", function () {
          $carousel.removeClass("no-js");
        });

        const $arrowContainer = $carousel.find(".slick-pagination .arrow-container");

        $("<button type=\"button\" class=\"slick-arrow-prev  slick-arrow slick-prev-btn\"><span class=\"sr-only\">Previous slide</span></button><button type=\"button\" class=\"slick-arrow-next  slick-arrow slick-next-btn\"><span class=\"sr-only\">Next slide</span></button>").appendTo($arrowContainer);

        // Settings
        $carousel.slick({
          infinite: true,
          slide: ".slide",
          variableWidth: false,
          adaptiveHeight: true,
          draggable: true,
          arrows: false,
          rows: 0,
          speed: 500,
          fade: true,
          cssEase: 'ease-in-out',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                dots: true
              }
            }
          ]
        }).on('setPosition', function (event, slick) {
          var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          if (windowWidth > 767) {
            slick.$slides.css('height', slick.$slideTrack.height() + 'px');
          } else {
            slick.$slides.css('height', "auto");
          }
        });

        // Center arrows perfectly in the middle of bottom container.
        const $arrowContainerHeight = $arrowContainer.height();
        $(".slick-pagination").css("bottom", - ($arrowContainerHeight / 2));
      });

      $(".carousel-reviews .carousel").on("click", ".slick-arrow-next", function (e) {
        $(this).parents(".carousel").slick("slickNext");
        $(e.delegateTarget).slick("slickNext");
      });

      $(".carousel-reviews").on("click", ".slick-arrow-prev", function () {
        $(this).parents(".carousel").slick("slickPrev");
      });

      $(".carousel-slider-hero").on("mousemove", ".slide-content__description-card-link", function (e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $(this).parents(".carousel").slick('slickGoTo', slideno - 1);
      });
      $(".carousel-slider-hero").css("visibility", "visible");

      // Carousel Four Column Cards
      $carouselFourCol.each(function () {
        const $this = $(this);
        const $carousel = $this.find(".carousel");

        // Callback function on carousel Initialize complete - must appear before carousel initialization
        $carousel.on("init", function () {
          $carousel.removeClass("no-js");
        });

        const $arrowContainer = $(".slick-pagination .arrow-container");

        // Determine if homepage or not.
        let $count = 4;

        // Settings
        $carousel.slick({
          arrows: false,
          prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous"><span class="sr-only">Previous</span></a>',
          nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next"><span class="sr-only">Next</span></a>',
          infinite: true,
          dots: true,
          variableWidth: false,
          adaptiveHeight: false,
          slidesToShow: $count,
          slidesToScroll: 1,
          draggable: true,
          rows: 0,
          appendArrows: $this.find($arrowContainer),
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: 'unslick'
            }
          ]

        });

        // Center arrows perfectly in the middle of bottom container.
        const $arrowContainerHeight = $arrowContainer.height();
        $(".slick-pagination").css("bottom", -($arrowContainerHeight / 2));

      });
    }
  }

  function initNewsCarouselMobile() {
    $("#news-release .mobile-collapse").click(function () {
      $("#news-release .carousel-news-events .carousel").slick("slickGoTo", 0);

      if ($(this).hasClass("collapsed")) {
        setTimeout(() => {
          $("#news-release .carousel-news-events").css("visibility", "visible");
        }, 500);
      }
      else {
        $("#news-release .carousel-news-events").css("visibility", "hidden");
      }
    });
  }

  initCarousel();
  initNewsCarouselMobile();
}
