
import $ from "jquery";

export default function () {

  function highlightAppointmentFields() {
    $('span.request-field-validation-error:visible').each(function (index, element) {
      $(element).parent().addClass('error');
      $(element).parent().find('input').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
      $(element).parent().find('select').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
      $(element).parent().find('div.btn-group').find('select').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
    });
  }

  function clearForm() {
    //blank everything out - we've submitted
    $('#firstName').val('');
    $('#lastName').val('');
    $('#email').val('');
    $('#phoneNumber').val('');
    //$("#preferred-contact option:selected").prop("selected", false);
    $('#reason').val('');
  }

  function undoAppointmentFieldHighlights(id) {
    id = id || null;
    if (id !== null) {
      var element = $('#' + id);
      $(element).parent().removeClass('error');
      $(element).parent().find('input').attr('aria-labelledby',
        $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      $(element).parent().find('select').attr('aria-labelledby',
        $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      $(element).parent().find('div.btn-group').find('select')
        .attr('aria-labelledby', $(element).parent().parent().find('label').attr('id'))
        .attr('aria-invalid', 'false');
    } else {
      $('span.request-field-validation-error').each(function (index, element) {
        $(element).parent().removeClass('error');
        $(element).parent().find('input').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
        $(element).parent().find('select').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
        $(element).parent().find('div.btn-group').find('select').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      });
    }
  }

  function createValidationSummary() {
    $('div.request-validation-summary-errors').remove();
    var errorMessages = $('span.request-field-validation-error:visible').map(function () {
      return $.trim($(this).text());
    }).get();


    if (errorMessages.length === 0) {
      return;
    }

    var valueToRemove = "";

    errorMessages = $.grep(errorMessages, function (value) {
      return value !== valueToRemove;
    });

    var errorMessageCount = $(errorMessages).length;
    var $header = $('div.request-appointment-form');
    var listHtml = "";
    $header.before("<div id='javascriptValidationSummary' role='alert' class='request-validation-summary-errors form-error-message' tabindex='-1' aria-live='polite'>");

    listHtml += "<strong class='form-error-message'>" + errorMessageCount + " error(s) were found in your form submission:</strong>";
    listHtml += "<ul>";
    $(errorMessages).each(function (index, element) {
      listHtml += ("<li>" + element + "</li>");
    });
    listHtml += "</ul>";
    $(listHtml).appendTo('#javascriptValidationSummary');
  }


  function validateFirstName() {
    var name = $('#firstName').val();

    if (name === null || name.match(/^ *$/) !== null) {
      $('#firstNameError').show();
      return 1;
    }

    return 0;
  }

  function validateLastName() {
    var name = $('#lastName').val();

    if (name === null || name.match(/^ *$/) !== null) {
      $('#lastNameError').show();
      return 1;
    }

    return 0;
  }

  function validateEmail() {
    var email = $('#email').val();
    var emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

    if (email === null || email.match(/^ *$/) !== null || email.match(emailRegex) === null) {
      $('#emailError').show();
      return 1;
    }

    return 0;
  }

  function validatePhone() {
    var phone = $('#phoneNumber').val();
    var phoneRegex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;

    if (phone === null || phone.match(/^ *$/) !== null || phone.match(phoneRegex) === null) {
      $('#phoneError').show();
      return 1;
    }
    return 0;
  }

  /*
  function validatePreferredContact() {
    var preferredContact = $('#preferred-contact').val();

    if (preferredContact === "") {
      $('#preferredContactError').show();
      return 1;
    }
    return 0;
  }
  */

  function validateRequestForm() {
    undoAppointmentFieldHighlights();
    $('span.request-field-validation-error').hide();

    var validationErrorCount = 0;

    validationErrorCount += validateFirstName();
    validationErrorCount += validateLastName();
    validationErrorCount += validateEmail();
    validationErrorCount += validatePhone();
    //validationErrorCount += validatePreferredContact();

    if (validationErrorCount !== 0) {
      $('#hiddenValidationErrorCount').val(validationErrorCount);
      highlightAppointmentFields();
      createValidationSummary();
      $('#javascriptValidationSummary').focus();
    } else {
      $('#hiddenValidationErrorCount').val(0);
      undoAppointmentFieldHighlights();
      $('#javascriptValidationSummary').hide();
    }

    return validationErrorCount === 0;
  }

  $(document).ready(function () {

    // only execute if on the request an apointment page
    if ($('#RequestAppointmentForm').length) {
  
      $('#requestSpinner').hide();

      $('.server-side-validation-errors').attr('aria-live', 'polite');
      $('.server-side-validation-errors').attr('tabindex', '-1');

      $('a.clear').click(function (e) {
        e.preventDefault();
        clearForm();
      });

      //we submitted successfully, now let's null out the fields
      if ($('#requestAppointmentSuccess').length !== 0) {
        clearForm();
      }

      $('#firstName').blur(function () {
        if (validateFirstName() === 0) {
          $('#firstNameError').hide();
          undoAppointmentFieldHighlights('firstName');
        } else {
          highlightAppointmentFields();
        }
        createValidationSummary();
      });

      $('#lastName').blur(function () {
        if (validateLastName() === 0) {
          $('#lastNameError').hide();
          undoAppointmentFieldHighlights('lastName');
        } else {
          highlightAppointmentFields();
        }
        createValidationSummary();
      });

      $('#email').blur(function () {
        if (validateEmail() === 0) {
          $('#emailError').hide();
          undoAppointmentFieldHighlights('email');
        } else {
          highlightAppointmentFields();
        }
        createValidationSummary();
      });

      $('#phoneNumber').blur(function () {
        if (validatePhone() === 0) {
          $('#phoneError').hide();
          undoAppointmentFieldHighlights('phoneNumber');
        } else {
          highlightAppointmentFields();
        }
        createValidationSummary();
      });

      /*
      $('#preferred-contact').change(function () {
        if (validatePreferredContact() === 0) {
          $('#preferredContactError').hide();
          undoAppointmentFieldHighlights('preferred-contact');
        } else {
          highlightAppointmentFields();
        }
        createValidationSummary();
      });
      */

      undoAppointmentFieldHighlights();
      highlightAppointmentFields();

      if ($('div.server-side-validation-errors').length === 0) {
        createValidationSummary();
      }

      $('#requestInfoFormSubmit').click(function (e) {
         e.preventDefault();
        $('#requestSpinner').show();
        $('#requestInfoFormSubmit').hide();
        $('#requestAppointmentErrorMessage').hide();
        $('#requestAppointmentSuccess').hide();

        var isValid = validateRequestForm();

        if (isValid) {

          var firstName = $('#firstName').val();
          var lastName = $('#lastName').val();
          var email = $('#email').val();
          var phone = $('#phoneNumber').val();
          var doctorId = $('#doctorId').val();
          var doctorName = $('#doctorName').val();
          var doctorSpecialty = $('#doctorSpecialty').val();
          var toAddress = $('#toAddress').val();
          var fromAddress = $('#fromAddress').val();
          //var preferredContact = $('#preferred-contact option:selected').val();
          var dataSourceId = $('#requestAppointmentDataSourceId').val();
          var tellUsMore = $('#tellUsMore').val();

          $.ajax({
            url: "/api/sitecore/RequestAppointmentForm/SubmitRequestAppointmentForm",
            type: 'POST',
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              phone: phone,
              //preferredContact: preferredContact,
              doctorId: doctorId,
              doctorName: doctorName,
              doctorSpecialty: doctorSpecialty,
              toAddress: toAddress,
              fromAddress: fromAddress,
              dataSourceId: dataSourceId,
              tellUsMore: tellUsMore
            }),
            success: function (response) {
              $('#requestSpinner').hide();

              if (response.SubmittedSuccessfully) {
                var dataLayer = window.dataLayer || [];
                dataLayer.push({'event':'submitSuccess', 'contactType':'Request an Appointment'});

                $('#requestAppointmentErrorMessage').hide();
                $('#requestAppointmentSuccess').show();
                $('#formElementRequestAppointmentForm').hide();
                clearForm();

                var offset = $("#RequestAppointmentForm").offset().top - 150;
                $("html, body").animate({ scrollTop: offset }, 500);
                $('#requestAppointmentSuccess').focus();
              }

              if (response.ErrorOnSubmission) {
                $('#requestInfoFormSubmit').show();
                $('#requestAppointmentSuccess').hide();
                $('#requestAppointmentErrorMessage').show();
                $('#requestAppointmentErrorMessage').focus();
              }
            },
            error: function (response) {
              var jsonResponse = JSON.parse(response);
              $('#requestSpinner').hide();

              if (jsonResponse !== null) {
                if (!jsonResponse.SubmittedSuccessfully || jsonResponse.ErrorOnSubmission) {
                  $('#requestAppointmentErrorMessage').show();
                  $('#requestAppointmentErrorMessage').focus();
                  $('#requestInfoFormSubmit').hide();
                } else {
                  $('#requestAppointmentErrorMessage').hide();
                  $('#requestAppointmentSuccess').show();
                  var offset = $("#RequestAppointmentForm").offset().top - 150;
                  $("html, body").animate({ scrollTop: offset }, 500);
                  $('#requestAppointmentSuccess').focus();
                }
              } else {
                $('#requestAppointmentErrorMessage').show();
                $('#requestAppointmentErrorMessage').focus();
                $('#requestInfoFormSubmit').hide();
              }
            }
          });
        } else {
          $('#requestSpinner').hide();
          $('#requestInfoFormSubmit').show();
        }
      });
    }
  });
}
