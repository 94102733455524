import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

export default function () {

  function initPhysicianFinder() {
    console.log('initPhysicianFinder');

    var searchCategory = "Physician";
    var $datasource = $('#hdnDatasource');
    var $clearBtn = $('.btn-clear');
    var datasource = $datasource.val();
    var $searchInput = $("#find-a-doctor");

    // bind click event on phys detail links
    bindPhysicianDetailLinks();

    var allSuggestions;
    if ($(".physician-finder-search").length) {
      bindPagination();

      // Get typeahead suggestions
      $.ajax({
        url: "/api/sitecore/PhysicianFinder/PhysicianSuggestions",
        type: "GET",
        data: { datasource: datasource },
        success: function (data) {
          allSuggestions = data;
        }
      });
    }

    function bindPagination() {
      $(".page").on("click", function (e) {
        e.preventDefault();
        var targetPage = $(this).data("page");
        selectPhysicians(targetPage);
      });

      $('#itemsPerPage').on("change", function () {
        selectPhysicians();
      });
    }

    function bindPhysicianDetailLinks() {
      $(".physician-detail-link").on("click", function (e) {
        e.preventDefault();
        var url = getPhysicianFinderUrl();

        var destUrl = $(this).data('physician-url');

        if (destUrl != null && destUrl !== '') {
          // Push full URL to history and redirect to original url
          history.replaceState(null, null, url);
          window.location.href = destUrl;
        }
        // otherwise, simply visit the built URL
        else {
          window.location.href = url;
        }
      });
    }


    // Build full URL including current physician finder filters in query string
    function getPhysicianFinderUrl() {
      let physFinderUrl = window.location.pathname;

      var primaryFilterType = $('#hdnPrimaryFilterType').val();
      if (primaryFilterType !== '') {
        var appliedFilter = $('#hdnAppliedFilter').val();
        if (appliedFilter !== '') {
          physFinderUrl = addQueryStringParameter(physFinderUrl, primaryFilterType, appliedFilter);
        }
      }

      var zipCode = $("#txtZipcode").val();
      if (zipCode !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "zipCode", zipCode);
      }

      var clinicalFocus = $("#selectClinicalFocuses").val();
      if (clinicalFocus !== '' && clinicalFocus !== 'All') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "clinicalFocus", clinicalFocus);
      }
      var condition = $("#selectConditions").val();
      if (condition !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "conditionId", condition);
      }
      var treatment = $("#selectTreatment").val();
      if (treatment !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "treatmentId", treatment);
      }
      var specialty = $("#selectSpecialty").val();
      if (specialty !== '' && specialty !== 'All') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "specialtyId", specialty);
      }
      var language = $("#selectLanguage").val();
      if (language !== '' && language !== 'All') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "language", language);
      }
      var gender = $("input[name='choiceGender']:checked").val();
      if (gender !== '' && gender !== 'All') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "gender", gender);
      }
      var acceptNewPatients = $("#chkAcceptNewPatients").is(':checked');
      if (acceptNewPatients == true) {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "acceptNewPatients", acceptNewPatients);
      }
      var lastName = $("#selectLastName").val();
      if (lastName !== '' && lastName !== 'All') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "lastName", lastName);
      }
      var query = $("#hdnQuery").val();
      if (query !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "q", query);
      }
      var primaryCare = $('#hdnPrimaryCare').val();
      if (primaryCare !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "primaryCare", primaryCare);
      }
      var pageSize = $("#itemsPerPage").val();
      if (pageSize !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "pageSize", pageSize);
      }
      var orderBy = $("#orderBy").val();
      if (orderBy !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "orderBy", orderBy);
      }
      var randomSeed = $("#hdnRandomSeed").val();
      if (randomSeed !== '') {
        physFinderUrl = addQueryStringParameter(physFinderUrl, "randomSeed", randomSeed);
      }

      physFinderUrl = addQueryStringParameter(physFinderUrl, "category",  searchCategory);

      return physFinderUrl;
    }


    // Add a query string parameter
    function addQueryStringParameter(url, key, value) {
      url += url.indexOf("?") > 0 ? "&" : "?";
      url += key + "=" + encodeURIComponent(value);
      return url;
    }

    $(".physician-finder-search select").on("change", function (e) {
      e.preventDefault();
      selectPhysicians(null, true);
    });
    $(".physician-finder-search #txtZipcode").on("input", function () {
      var zip = $(this).val();
      if (zip.length === 5) {
        $("#orderBy").val("distance");
        selectPhysicians(null, true);
      } else if (zip === "") {
        selectPhysicians(null, true);
      }
    });
    $(".physician-finder-search #txtZipcode").on("keypress", function (e) {
      if (e.which < 48 || e.which > 57) {
        e.preventDefault();
      }
    });
    $(".physician-finder-search input[name=choiceGender]").on("change", function (e) {
      e.preventDefault();
      selectPhysicians(null, true);
    });
    $(".physician-finder-search #chkAcceptNewPatients").on("change", function (e) {
      e.preventDefault();
      selectPhysicians(null, true);
    });

    $('.physician-finder-search .results-filter-link[data-sort]').on("click", function (e) {
      e.preventDefault();
      var sort = $(this).data("sort");
      if (sort !== "") {
        $("#orderBy").val(sort);
        selectPhysicians();
        $('.physician-finder-search .results-filter-link[data-sort]').removeClass("active");
        $(this).addClass("active");
      }
    });

    function selectPhysicians(page, triggerGtmEvent) {
      if (typeof page === "undefined" || page === null) {
        page = 1;
      }

      // set default value for this optional parameter
      triggerGtmEvent = triggerGtmEvent === true;

      if (triggerGtmEvent) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          "event": "refinePrimaryCare",
          "zipcode": $("#txtZipcode").val(),
          "clinicalFocus": $("#selectClinicalFocuses option:selected").text(),
          "condition": $("#selectConditions option:selected").text(),
          "treatment": $("#selectTreatment option:selected").text(),
          "specialty": $("#selectSpecialty option:selected").text(),
          "doctorLanguage": $("#selectLanguage option:selected").text(),
          "gender": $("input[name='choiceGender']:checked").data("radiotext"),
          "newPatients": $("#chkAcceptNewPatients").is(':checked')
        });
      }
      let spinner = '<li><p style="text-align: center;"><img src="/ui/images/spinner.gif" alt="Waiting Spinner"></p></li>';
      $("#search-results-list li").remove();
      $("#search-results-list").append(spinner);

      $.ajax({
        url: "/api/sitecore/physicianFinder/SearchPhysicianResults",
        type: "POST",
        data: {
          datasource: $datasource.val(),
          zipcode: $("#txtZipcode").val(),
          clinicalFocus: $("#selectClinicalFocuses").val(),
          condition: $("#selectConditions").val(),
          treatment: $("#selectTreatment").val(),
          specialty: $("#selectSpecialty").val(),
          language: $("#selectLanguage").val(),
          gender: $("input[name='choiceGender']:checked").val(),
          acceptNewPatients: $("#chkAcceptNewPatients").is(':checked'),
          lastName: $("#selectLastName").val(),
          query: $("#hdnQuery").val(),
          primaryCare: $('#hdnPrimaryCare').val(),
          pageSize: $("#itemsPerPage").val(),
          orderBy: $("#orderBy").val(),
          page: page,
          randomSeed: $("#hdnRandomSeed").val()
        },
        context: this,
        success: function (data) {
          var resultHtml = $.parseHTML(data.trim());

          // Set the physician results into proper location in dom
          var physDiv = $(resultHtml).find("#divPhysicians");
          if (physDiv.length) {
            $("#search-results-list").html(physDiv.html());
            $('#resultCount').text("(" + $('#maxResults').val() + ")");
          }

          // Set pagination into proper location in dom
          var paginationDiv = $(resultHtml).find("#divPagination");
          if (paginationDiv.length) {
            $(".page-pagination").html(paginationDiv.html());
          }

          // Sets the sort button active, mostly applies for setting distance sort after passing in a zip
          $('.physician-finder-search .results-filter-link[data-sort]').removeClass("active");
          $('.physician-finder-search .results-filter-link[data-sort="' + $("#orderBy").val() + '"]').addClass("active");

          // Have to rebind pagination after adding new elements
          bindPagination();

          // bind click event on phys detail links
          bindPhysicianDetailLinks();

          var offset = $("#search-results-list").offset().top - 150;
          $("html, body").animate({ scrollTop: offset }, 500);
        },
        error: function (data) {
          console.log("error", data);
        }
      });
    }

    if ($(".physician-finder-search").length) {
      var offset = $("#search-results-list").offset().top - 150;
      $("html, body").animate({ scrollTop: offset }, 500);
    }

    function suggestionsWithDefaults(q, sync) {
      if (q === '') {
        sync(allSuggestions);
      }
    }

    var physicians = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/PhysicianFinder/SearchPhysicians?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          return response.Physicians;
        }
      }
    });

    var allConditions;
    var conditions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/PhysicianFinder/SearchPhysicians?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allConditions = response.Conditions;
          buildModal(allConditions, "conditions");
          return response.Conditions;
        }
      }
    });

    var allSpecialties;
    var specialties = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/PhysicianFinder/SearchPhysicians?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allSpecialties = response.Specialties;
          buildModal(allSpecialties, "specialties");
          return response.Specialties;
        }
      }
    });

    var allTreatments;
    var treatments = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/PhysicianFinder/SearchPhysicians?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allTreatments = response.Treatments;
          buildModal(allTreatments, "treatments");
          return response.Treatments;
        }
      }
    });

    function buildModal(items, identifier) {
      var targetEle = $('#' + identifier + 'Modal').find(".modal-body");
      targetEle.empty(); // clear previous results

      var numOfLists = 3;

      var cleanList = items.filter(function (item) {
        return item.Name !== "View All";
      });

      var lists = [], index = 0;
      // for each column (or list) - l indicates the number of lists left to render
      for (var l = numOfLists; l > 0; l--) {
        // Figure out the length of the next column by dividing by the # of columns left
        var length = Math.floor(((cleanList.length - index) + (l - 1)) / l);
        // Initiate a blank list
        var list = $('<ul class="modal-list"/>');
        // Fill out the list
        for (var i = 0; i < length; i++) {
          // Meanwhile, track the index of where we are in the main list
          var item = cleanList[index++];
          list.append($('<li class="modal-list-item"><a href="' +
            item.Url + '" class="modal-list-link">' +
            item.Name + '</a></li>'));
        }
        lists.push(list);
      }

      var row = $('<div class="row"/>');
      lists.forEach(function (list) {
        row.append($('<div class="col-lg-4"/>').append(list));
      });
      targetEle.append(row);
    }

    $('.modal').on('hidden.bs.modal', function () {
      $searchInput.focus();
    });

    $searchInput.typeahead({
      highlight: true,
      minLength: 0,
      hint: false,
      classNames: {
        suggestion: 'search-autocomplete-item',
        menu: 'search-autocomplete',
        dataset: 'search-autocomplete-listing'
      }
    },
    {
      name: "suggestions",
      display: "Name",
      source: suggestionsWithDefaults,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Popular Specialties</h2></div>",
        suggestion: function suggestion(data) {
          return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
        }
      }
    },
    {
      name: "physicians",
      display: "Name",
      source: physicians,
      limit: 6,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Physicians</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='" + data.Url+ "' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Physicians</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + ((data.ImageUrl !== null) ? "<span class='search-autocomplete-profile'><img src='" + data.ImageUrl + "' alt='Profile'></span>" : "") + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "conditions",
      display: "Name",
      source: conditions,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Conditions</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#conditionsModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Conditions</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "specialties",
      display: "Name",
      source: specialties,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Specialties</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#specialtiesModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Specialties</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "treatments",
      display: "Name",
      source: treatments,
      limit: 4,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Treatments</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#treatmentsModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Treatments</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      }
    });

    var $selectedItem;

    $searchInput.on('keyup', function (e) {

      // If keys pressed are up or down. Store ttcursor.
      if (e.which == 38 || e.which == 40) {
        $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
      }

      // If enter has been clicked, use the item in $selected item to perform the click.
      if (e.which == 13) {
        if ($selectedItem) {
          $selectedItem.click();
        }
      }

      if ($searchInput.val() !== "") {
        $clearBtn.addClass("active");
      } else {
        $clearBtn.removeClass("active");
      }
    });

    $clearBtn.on('click', function () {
      $clearBtn.removeClass("active");
      $searchInput.focus();
      $searchInput.val(''); // Clear input value
      $searchInput.typeahead('val', ''); // Clear typeahead value
    });
  }

  if ($(".physician-finder-search").length) {
    initPhysicianFinder();
  }
}
