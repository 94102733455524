import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

export default function () {

  function initTypesAndTreatmentsTypeahead() {
    console.log('initTypesAndTreatmentsTypeahead');

    var $datasource = $('#hdnDatasource');
    var $clearBtn = $('.btn-clear');
    var datasource = $datasource.val();
    var $searchInput = $('#find-a-cancer-type-treatment');
    var $searchBtn = $('#btnSearch');

    var allCancertypes;
    var cancertypes = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/CancerTypesAndTreatmentsSearch/SearchCancerTypes?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allCancertypes = response.CancerTypes;
          buildModal(allCancertypes, "cancertypes");
          return response.CancerTypes;
        }
      }
    });

    var allCancerTreatments;
    var cancertreatments = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        rateLimitWait: "500",
        wildcard: "%QUERY",
        url: "/api/sitecore/CancerTypesAndTreatmentsSearch/SearchCancerTypes?query=%QUERY&datasource=" + datasource,
        transform: function transform(response) {
          allCancerTreatments = response.CancerTreatments;
          buildModal(allCancerTreatments, "cancertreatments");
          return response.CancerTreatments;
        }
      }
    });

    function buildModal(items, identifier) {
      var targetEle = $('#' + identifier + 'Modal').find(".modal-body");
      targetEle.empty(); // clear previous results

      var numOfLists = 3;

      var cleanList = items.filter(function (item) {
        return item.Name !== "View All";
      });

      //sort it
      cleanList.sort((a, b) => a.Name && a.Name.localeCompare(b.Name));

      var lists = [], index = 0;
      // for each column (or list) - l indicates the number of lists left to render
      for (var l = numOfLists; l > 0; l--) {
        // Figure out the length of the next column by dividing by the # of columns left
        var length = Math.floor(((cleanList.length - index) + (l - 1)) / l);
        // Initiate a blank list
        var list = $('<ul class="modal-list"/>');
        // Fill out the list
        for (var i = 0; i < length; i++) {
          // Meanwhile, track the index of where we are in the main list
          var item = cleanList[index++];
          list.append($('<li class="modal-list-item"><a href="' +
            item.Url + '" class="modal-list-link">' +
            item.Name + '</a></li>'));
        }
        lists.push(list);
      }

      var row = $('<div class="row"/>');
      lists.forEach(function (list) {
        row.append($('<div class="col-lg-4"/>').append(list));
      });
      targetEle.append(row);
    }

    $('.modal').on('hidden.bs.modal', function () {
      $searchInput.focus();
    });

    $searchInput.typeahead({
      highlight: true,
      minLength: 0,
      hint: false,
      classNames: {
        suggestion: 'search-autocomplete-item',
        menu: 'search-autocomplete',
        dataset: 'search-autocomplete-listing'
      }
    },
    {
      name: "cancertypes",
      display: "Name",
      source: cancertypes,
      limit: 6,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Cancer Type</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#cancertypesModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Cancer Types</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='" + data.Name + "'>" + data.Name + "</a></div>";
          }
        }
      }
    },
    {
      name: "cancertreatments",
      display: "Name",
      source: cancertreatments,
      limit: 6,
      templates: {
        header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Cancer Treatment</h2></div>",
        suggestion: function suggestion(data) {
          if (data.Name === "View All") {
            return "<div class='search-autocomplete-link'><a href='#' data-toggle='modal' data-target='#cancertreatmentsModal' class='btn-link btn-arrow btn-arrow-next'>View All<span class='sr-only'> Cancer Treatments</span></a></div>";
          } else {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='" + data.Name + "'>" + data.Name + "</a></div>";
          }
        }
      }
    });


    var $selectedItem;

    $searchInput.on('keyup', function (e) {

      // If keys pressed are up or down. Store ttcursor.
      if (e.which == 38 || e.which == 40) {
        $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
      }

      // If enter has been clicked, use the item in $selected item to perform the click.
      if (e.which == 13) {
        if ($selectedItem) {
          $selectedItem.click();
        }
        else {
          $searchBtn.click();
        }
      }

      else {
        if ($searchInput.val() !== "") {
          $clearBtn.addClass("active");
        } else {
          $clearBtn.removeClass("active");
        }
      }
    });

    $clearBtn.on('click', function () {
      $clearBtn.removeClass("active");
      $searchInput.focus();
      $searchInput.val(''); // Clear input value
      $searchInput.typeahead('val', ''); // Clear typeahead value
    });

    $searchBtn.on('click', function () {
       if ($searchInput.val() !== "") {
         var searchUrl = '/search';
         window.location = searchUrl + '?Filter=All%20Conditions%20%26%20Care&q=' + $searchInput.val();
       }
    });
  }

  if ($('#find-a-cancer-type-treatment').length) {
    initTypesAndTreatmentsTypeahead();
  }
}
