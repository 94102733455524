import $ from "jquery";
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");

export default function () {

  function initConditionsCareTypeahead() {
    console.log('initConditionsCareTypeahead');

    var searchInput = $("#conditions-care-search-input");

    var allSuggestions;
    function suggestionsWithDefaults(q, sync) {
      if (q === '') {
        sync(allSuggestions);
      }
    }

    if (searchInput.length) {
      // Get typeahead suggestions
      var datasource = $('#hdnDatasource').val();
      
      $.ajax({
        url: "/api/sitecore/SearchApi/CareConditionsSuggestions",
        type: "GET",
        data: { datasource: datasource },
        success: function (data) {
          allSuggestions = data;
        }
      });

      var specialties = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          rateLimitWait: "500",
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetCareConditionsTypeaheadResults?query=%QUERY",
          transform: function transform(response) {
            return response.Specialties;
          }
        }
      });

      var centers = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          rateLimitWait: "500",
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetCareConditionsTypeaheadResults?query=%QUERY",
          transform: function transform(response) {
            return response.Centers;
          }
        }
      });

      var conditions = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          rateLimitWait: "500",
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetCareConditionsTypeaheadResults?query=%QUERY",
          transform: function transform(response) {
            return response.Conditions;
          }
        }
      });

      var treatments = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          rateLimitWait: "500",
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetCareConditionsTypeaheadResults?query=%QUERY",
          transform: function transform(response) {
            return response.Treatments;
          }
        }
      });

      var physiciansWhoTreat = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          rateLimitWait: "500",
          wildcard: "%QUERY",
          url: "/api/sitecore/SearchApi/GetCareConditionsTypeaheadResults?query=%QUERY",
          transform: function transform(response) {
            return response.PhysiciansWhoTreat;
          }
        }
      });

      searchInput.typeahead({
        highlight: true,
        minLength: 0,
        hint: false,
        classNames: {
          suggestion: 'search-autocomplete-item',
          menu: 'search-autocomplete',
          dataset: 'search-autocomplete-listing'
        }
      },
      {
        name: "suggestions",
        display: "Name",
        source: suggestionsWithDefaults,
        templates: {
          header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Popular Searches</h2></div>",
          suggestion: function suggestion(data) {
            return "<div><a href='" + data.Url + "' class='search-autocomplete-link'>" + data.Name + "</a></div>";
          }
        }
      },
      {
        name: "specialties",
        display: "Name",
        source: specialties,
        limit: 4,
        templates: {
          header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Specialties</h2></div>",
          suggestion: function suggestion(data) {
            if (data.Name === "View All") {
              return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='specialties'>" + data.Name + "</a></div>";
            }
          }
        }
      },
      {
        name: "centers",
        display: "Name",
        source: centers,
        limit: 4,
        templates: {
          header: "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Centers</h2></div>",
          suggestion: function suggestion(data) {
            if (data.Name === "View All") {
              return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='centers'>" + data.Name + "</a></div>";
            }
          }
        }
      },
      {
        name: "conditions",
        display: "Name",
        source: conditions,
        limit: 4,
        templates: {
          header:
            "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Conditions</h2></div>",
          suggestion: function suggestion(data) {
            if (data.Name === "View All") {
              return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='conditions'>" + data.Name + "</a></div>";
            }
          }
        }
      },
      {
        name: "treatments",
        display: "Name",
        source: treatments,
        limit: 4,
        templates: {
          header:"<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Treatments</h2></div>",
          suggestion: function suggestion(data) {
            if (data.Name === "View All") {
              return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='treatments'>" + data.Name + "</a></div>";
            }
          }
        }
      },
      {
        name: "physiciansWhoTreat",
        display: "Name",
        source: physiciansWhoTreat,
        limit: 4,
        templates: {
          header:"<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Physicians Who Treat</h2></div>",
          suggestion: function suggestion(data) {
            if (data.Name === "View All") {
              return "<div class='search-autocomplete-link'><a href='" + data.Url + "' class='btn-link btn-arrow btn-arrow-next'>View All</a></div>";
            } else {
              return "<div><a href='" + data.Url + "' class='search-autocomplete-link data-gtm-typeaheadCare' data-gtm-category='treatments'>" + data.Name + "</a></div>";
            }
          }
        }
      });

      // Determine the type ahead category order based on page. Let's get the category that's selected.
      var pathname = window.location.pathname;

      var $suggestions = $("div.search-autocomplete-listing-suggestions");

      if (pathname.indexOf("care/centers") >= 0) {
        $("div.search-autocomplete-listing-centers").insertAfter($suggestions);
      }

      if (pathname.indexOf("care/conditions") >= 0) {
        $("div.search-autocomplete-listing-conditions").insertAfter($suggestions);
      }

      if (pathname.indexOf("care/treatments") >= 0) {
        $("div.search-autocomplete-listing-treatments").insertAfter($suggestions);
      }

      var $clearBtn = $('#btnClearSearch');

      var $selectedItem;

      searchInput.on('keyup', function (e) {

        // If keys pressed are up or down. Store ttcursor.
        if (e.which == 38 || e.which == 40) {
          $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
        }

        // If enter has been clicked, use the item in $selected item to perform the click.
        if (e.which == 13) {
          if ($selectedItem) {
            $selectedItem.click();
          }
        }

        else {
          if (searchInput.val() !== "") {
            $clearBtn.addClass("active");
          } else {
            $clearBtn.removeClass("active");
          }
        }
      });

      $clearBtn.on('click', function () {
        $clearBtn.removeClass("active");
        searchInput.focus();
        searchInput.val(''); // Clear input value
        searchInput.typeahead('val', ''); // Clear typeahead value
      });
    }
  }

  if ($("#conditions-care-search-input").length) {
    initConditionsCareTypeahead();
  }
}
