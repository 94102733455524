// Import
import $ from 'jquery';
import 'bootstrap';
import 'slick-carousel';
import 'select-woo';
import 'corejs-typeahead';
import './vendor/jquery.unobtrusive-ajax.min.js';
import './vendor/jquery.validate.min.js';
import './vendor/jquery.validate.unobtrusive.min.js';
import './vendor/form.tracking.js';
import './vendor/form.validate.js';

import initLocationSearchMap from './apps/locationSearchMap.js';
import initCarouselPage from './apps/carousel.js';
import initUserInterface from './userinterface.js';
import initSelectFields from './apps/select2.js';
import initDatepicker from './apps/datepicker.js';
import initEventsPage from './apps/events.js';
import initPhysicianFinderPage from './apps/physician-finder.js';
import initResearcherFinderPage from './apps/researcher-finder.js';
import initClinicalTrialsPage from './apps/clinical-trials.js';
import initTypesAndTreatmentsTypeahead from './apps/types-and-treatments.js';
import initConditionsCareTypeahead from './apps/conditions-care.js';
import initRequestAppointmentPage from './apps/request-appointment.js';
import initEmailSubscription from './apps/email-subscription.js';
import initEmailSubscriptionShort from './apps/email-subscription-short.js';
import initSearch from './apps/search.js';
// Added by David Cook  02-03-2021 - Updated 07/06/2021
import initCorpHealthForm from './apps/corphealth.js';
import initLocationSearch from './apps/location-search.js';

import {
  initHomeScroll,
  initAnimateBlocks,
  initAnimateHeroBlock,
  initRightRailNav,
  initAnimateHomeEvent
} from './apps/scrollEffects.js';

const googleMapsApi = require('load-google-maps-api');

$(document).ready(function () {
  // init scripts being imported
  //debugger; // eslint-disable-line
  initCarouselPage();
  initUserInterface();
  initSelectFields();
  initHomeScroll();
  initAnimateBlocks();
  initAnimateHeroBlock();
  initRightRailNav();
  initDatepicker();
  initEventsPage();
  initPhysicianFinderPage();
  initResearcherFinderPage();
  initClinicalTrialsPage();
  initTypesAndTreatmentsTypeahead();
  initConditionsCareTypeahead();
  initRequestAppointmentPage();
  initEmailSubscription();
  initEmailSubscriptionShort();
  initAnimateHomeEvent();
  initSearch();
  // Added by David Cook  02-03-2021
  initCorpHealthForm();
  //initAutocomplete();
  initLocationSearch();

  let options = { key: 'AIzaSyDGq2ZihZFIdCz99te0XxQBr9Ii8MLLh0k'};

  googleMapsApi(options).then(function (maps) {
    window.googleMapsApi = maps;
    initLocationSearchMap();
    // initAutocomplete();
  }).catch(function (error) {
    console.error(error);
  });

  // Bootstrap Select
  //$(".selectpicker").selectpicker();
});
