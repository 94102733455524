
import $ from "jquery";

export default function () {

  function highlightSubscribeFields() {
    $('span.subscribe-field-validation-error:visible').each(function (index, element) {
      $(element).parent().find('input').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
      $(element).parent().find('select').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
      $(element).parent().find('div.btn-group').find('select').attr('aria-labelledby', $(element).attr('id')).attr('aria-invalid', 'true');
    });
  }

  function undoSubscribeFieldHighlights(id) {
    id = id || null;
    if (id !== null) {
      var element = $('#' + id);
      $(element).parent().find('input').attr('aria-labelledby',
        $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      $(element).parent().find('select').attr('aria-labelledby',
        $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      $(element).parent().find('div.btn-group').find('select')
        .attr('aria-labelledby', $(element).parent().parent().find('label').attr('id'))
        .attr('aria-invalid', 'false');
    } else {
      $('span.subscribe-field-validation-error').each(function (index, element) {
        $(element).parent().find('input').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
        $(element).parent().find('select').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
        $(element).parent().find('div.btn-group').find('select').attr('aria-labelledby', $(element).parent().parent().find('label').attr('id')).attr('aria-invalid', 'false');
      });
    }
  }

  /**********************
   * Constant Variables *
   **********************/
  var formClassName = '.short-subscription-form';
  var formId = 'formShortSubscriptionForm';

  /*  DEV SETTINGS
  var aidValue = '39815';
  var fidValue = 'd7a915d9-70e7-4249-886c-a209790d491f';
  var serverValue = 'cidev.actonsoftware.com';
  */

  var aidValue = '19609';
  var fidValue = '811342cb-5c9d-4e6d-9192-a335cfb44384';
  var serverValue = 'cid3.actonsoftware.com';
  
  function validateRequestForm() {
    undoSubscribeFieldHighlights();
    $('span.subscribe-field-validation-error').hide();
    var validationErrorCount = 0;

    validationErrorCount += validateSubscribeEmail();

    if (validationErrorCount !== 0) {
      $('#hiddenValidationErrorCount').val(validationErrorCount);
      highlightSubscribeFields();
      $('#javascriptValidationSummary').focus();
    } else {
      $('#hiddenValidationErrorCount').val(0);
      undoSubscribeFieldHighlights();
      $('#javascriptValidationSummary').hide();
    }

    return validationErrorCount === 0;
  }

  function validateSubscribeEmail() {
    var email = $('#subscribeEmail').val();
    var emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

    if (email === null || email.match(/^ *$/) !== null || email.match(emailRegex) === null) {
      $('#subscribeEmailError').show();
      return 1;
    }

    return 0;
  }

  $('#subscribeEmail').blur(function () {
    if (validateSubscribeEmail() === 0) {
      $('#subscribeEmailError').hide();
      undoSubscribeFieldHighlights('subscribeEmail');
    } else {
      highlightSubscribeFields();
    }
  });

  $(function () {
    var $form = $(formClassName);
    if ($form.length !== 0) {

      $('#btnSubscribeSubmit').click(function (e) {
        e.preventDefault();
        $('#subscribeFormSubmit').hide();
        $('#subscribeErrorMessage').hide();
        $('#subscribeSuccess').hide();

        var isValid = validateRequestForm();

        if (isValid) {

          var email = $('#subscribeEmail').val();
          var subscription = $('#subscription').val();

          $.ajax({
            async: false,
            url: serverValue,
            type: 'POST',
            data: JSON.stringify({
              'Email Address': email,
              'Email Subscription': subscription
            }),
            success: function () {
              var dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'subscribeSuccess'
              });

              setTimeout(AoProcessForm($form[0]), 0);
              $form.hide();
              
              $('#subscribeErrorMessage').hide();
              $('#subscribeSuccess').show();

            }, error: function (xhr, ajaxOptions, thrownError) {
              console.log("Short subscribe form error, thrownError=" + thrownError + ", xhr=" + xhr + ", ajaxOptions=" + ajaxOptions);
              $('#subscribeFormSubmit').show();
              $('#subscribeSuccess').hide();
              $('#subscribeErrorMessage').show();
              $('#SubscribeErrorMessage').focus();

            }
          });

        } else {
          $('#subscribeFormSubmit').show();
          $('#subscribeSuccess').hide();
          $('#subscribeErrorMessage').show();
          $('#subscribeErrorMessage').focus();
        }
      });
    }


    /***********************************************************************************************************************************
     * Act-On Integration - JavaScript Triggered by Custom Event - Customized code from the link below                                 *
     * https://university.act-on.com/Product_Features/Content/Forms/Publishing_Forms/Posting_External_Forms_to_Act-On_with_Custom_Code *
     ***********************************************************************************************************************************/
    var aoProtocol = location.protocol;
    if (aoProtocol.indexOf('http') < 0) aoProtocol = 'http:';
    var aoCAP = {
      aid: aidValue,
      fid: fidValue,
      did: 'd-0001',
      server: serverValue,
      formName: formId,
      protocol: aoProtocol
    };
    var aoArr = aoArr || []; aoArr.push(aoCAP);

    function AoProcessForm(formelement) {
      var AoI;
      for (AoI = 0; AoI < aoArr.length; AoI++) {
        if (aoArr[AoI].aid && aoArr[AoI].fid && aoArr[AoI].did && aoArr[AoI].server && (aoArr[AoI].formId || aoArr[AoI].formName)) {
          var d = document,
            thisFormId = formelement.id || '',
            thisFormName = formelement.name || '',
            bi = function (i) {
              return d.getElementById(i);
            },
            bn = function (i) {
              return d.getElementsByName(i)[0];
            },
            names = [],
            values = [],
            params = {},
            w = window,
            targetIdOrName = aoArr[AoI].formName ? bn(aoArr[AoI].formName) : bi(aoArr[AoI].formId),
            len = targetIdOrName.elements.length,
            isLoaded = false,
            ud = 'undefined',
            st = function (f, i) {
              w.setTimeout(f, i);
            },
            ce = function (t) {
              return d.createElement(t);
            },
            gid = function (p) {
              var j, i = 0,
                n = Math.random,
                r = [],
                c = '0123456789abcdef'.split('');
              for (; i < 16; i++) r[i] = c[(0 | n() * 16) & 0xf];
              j = p + r.join('');
              return bi(j) == null ? j : gid(p);
            },
            addInput = function (form, name, value) {
              var el = ce('input');
              el.name = name;
              el.value = value;
              form.appendChild(el);
            },
            idifr = gid('aoCapT');

          if (aoArr[AoI].formName == thisFormName || aoArr[AoI].formId == thisFormId) {
            var dTarget = ce('div');
            dTarget.style.display = 'none';
            d.body.appendChild(dTarget);
            dTarget.innerHTML = '<iframe name="' + idifr + '" id="' + idifr + '"><\/iframe>'; // generate iframe

            var dForm = ce('form'), idform = gid('aoCapD');
            dForm.id = idform;
            dForm.style.display = "none";
            dForm.method = 'POST';
            dForm.enctype = 'multipart/form-data';
            dForm.acceptCharset = 'UTF-8';
            dForm.target = idifr; // form targets iframe
            dForm.action = (aoCAP.protocol || w.location.protocol) + '//' + aoCAP.server + '/acton/forms/userSubmit.jsp';
            d.body.appendChild(dForm); // generate form

            var i;
            for (i = 0; i < len; i++) {
              var el = targetIdOrName.elements[i];
              var name = typeof (el.name) != ud ? el.name : null;

              var tagName = el.nodeName.toLowerCase();
              if (tagName == 'input' && (el.type == 'radio' || el.type == 'checkbox') && !el.checked) {
                name = null;
              }

              if (name != null && name != '') {
                names.push(name);
                values.push(el.value);
                console.log("Element name: " + el.name + " / Element value: " + el.value);
                params[name] = el.value;
              }
              addInput(dForm, el.name, el.value);
            }

            aoCAP.pid = 0;
            aoCAP.cuid = aoCAP.cuid || '';
            aoCAP.srcid = aoCAP.srcid || '';
            aoCAP.camp = aoCAP.camp || '';
            addInput(dForm, 'ao_a', aoArr[AoI].aid);
            addInput(dForm, 'ao_f', aoArr[AoI].fid);
            addInput(dForm, 'ao_d', aoArr[AoI].fid + ":" + aoArr[AoI].did);
            addInput(dForm, 'ao_p', 0);
            addInput(dForm, 'ao_jstzo', new Date().getTimezoneOffset());
            addInput(dForm, 'ao_form_neg_cap', '');
            addInput(dForm, 'ao_refurl', d.referrer);
            addInput(dForm, 'ao_cuid', aoCAP.cuid);
            addInput(dForm, 'ao_srcid', aoCAP.srcid);
            addInput(dForm, 'ao_camp', aoCAP.camp);
            bi(idform).submit();

            var dTargetFrame = bi(idifr);
            dTargetFrame.onload = function () {
              isLoaded = true;
            };
            var waitForSubmit = function () {
              this.count = "";
              if (!(isLoaded || dTargetFrame.readyState == "complete")) {
                st(waitForSubmit, 200);
                this.count++;
              } else if (this.count > 7) {
                console.log("skipping dForm");
                return true;
              }
              else {
                d.body.removeChild(dForm);
                d.body.removeChild(dTarget);
              }
            };
            st(waitForSubmit, 100);
          }
        } else {
          console.log('aoCAP property missing');
        }
      }
    }

  });
}
