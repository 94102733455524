/* eslint-disable */
//dependencies
import $ from "jquery";
import MarkerClusterer from '@google/markerclustererplus';

export default function () {
    $(document).ready(function () {
        let mapElement = document.getElementById('map-v3');
        if (!mapElement) return;
        $(mapElement).empty();
        let maps = window.googleMapsApi;
        let markerClusters;
        let minClusterClickZoomLevel = 14; //should be less than closeupZoomLevel
        let defaultZoomLevel = 10;
        let closeupZoomLevel = 15;

        let maxMapZoomLevel = 23;
        let markerGroup = {};

        const styledMapType = new maps.StyledMapType(
            [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#931300"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ],
            { name: 'Styled Map' });

        let mapImagePath = '/ui/images';
        //let mapImagePath = '..'; //local testing

        let inactivePin =  mapImagePath + '/map/black-mapPin.png';
        let activePin = mapImagePath + '/map/red-mapPin.png';

        let pin = inactivePin;
        var mcOptions = {
            styles: [
                {
                    height: 56,
                    width: 55,
                    anchor: [-20, -20],
                    url: mapImagePath + "/map/m1.png",
                    textColor: 'white',
                    textSize: 16
                },
                {
                    height: 56,
                    width: 55,
                    anchor: [-20, -20],
                    url: mapImagePath + "/map/m1.png",
                    textColor: 'white',
                    textSize: 16
                },
                {
                    height: 56,
                    width: 55,
                    anchor: [-20, -20],
                    url: mapImagePath + "/map/m1.png",
                    textColor: 'white',
                    textSize: 16
                },
                {
                    height: 56,
                    width: 55,
                    anchor: [-20, -20],
                    url: mapImagePath + "/map/m1.png",
                    textColor: 'white',
                    textSize: 16
                },
                {
                    height: 56,
                    width: 55,
                    anchor: [-20, -20],
                    url: mapImagePath + "/map/m1.png",
                    textColor: 'white',
                    textSize: 16
                }
            ]
        };

        let Markers = [];
        let infoWindowContent = {}; //used as a pointer to the infowindow contents themselves.

        let addressLinks = $('div.map-content');

        let locations = [];

        setUpLocations();

        addressLinks.each(function (index, link) {
            setUpLinkClicks(link);
        });

        let headerLinks = $('a.location-name-link');

        headerLinks.each(function (index, link) {
            setUpLinkClicks(link);
        });

        let j;

        let map = new google.maps.Map(document.getElementById('map-v3'), {
            zoom: defaultZoomLevel,
            maxZoom: maxMapZoomLevel,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            keyboardShortcuts: false
        });

      // Click to zoom to map area
      $(".map-content").on('click', function () {
        var lng = $(this).data('longitude');
        var lat = $(this).data('latitude');
        var zoom = 10;
        var LatLng = new google.maps.LatLng(lat, lng);
        map.setZoom(zoom);
        map.panTo(LatLng);
      });

        //Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');

        let bounds = new google.maps.LatLngBounds();

        for (j = 0; j < locations.length; j++) {
            let recordId = addressLinks.eq(j).attr('data-addressid');

            let marker = new google.maps.Marker({
                record_id: recordId,
                position: locations[j].location,
                map: map,
                icon: pin
            });

            marker.addListener('click', function (marker, i) {
                return function () {
                    const dataDiv = $("div.map-content[data-addressid='" + marker.record_id + "']");

                    $(dataDiv).click();

                    //make the item scroll to make sure it's visible. Get the offset of the active car relative to the top of the container, and scroll to it.
                    const childPos = $(dataDiv).offset();
                    const parentPos = $(".location-map-content-wrapper").offset();
                    const childOffset = {
                        top: childPos.top - parentPos.top,
                        left: childPos.left - parentPos.left
                    };

                    $(".location-map-content-wrapper").animate({
                        scrollTop: $(".location-map-content-wrapper").scrollTop() + childOffset.top
                    }, 750);

                }
            }(marker));

            google.maps.event.addListener(marker, 'click', function (event) {

                //infowindow.setContent(content);
                marker.setIcon(activePin);

                //reset all other pins to inactive
                for (var i=0; i<Markers.length; i++) {
                    if (Markers[i].record_id !== marker.record_id) {
                        Markers[i].setIcon(inactivePin);
                    }
                }

                console.log("zoom level" + closeupZoomLevel);
                map.setZoom(closeupZoomLevel);
                map.panTo(marker.position);

            });

            //infoWindowContent[marker.record_id] = contentString;

            Markers.push(marker);
            markerGroup[marker.record_id] = marker;

            bounds.extend(marker.getPosition());
        }

        markerClusters = new MarkerClusterer(map, Markers, {
            maxZoom: minClusterClickZoomLevel,  //once you get down to this level, the clusters disappear
            styles: mcOptions['styles']
        });


        function changeMarker(recordId) {
            let i;
            for (i = 0; i < Markers.length; i++) {
                if (Markers[i].record_id === recordId) {
                    Markers[i].setIcon(activePin);
                } else {
                    Markers[i].setIcon(inactivePin);
                }
            }
        }

        $("a.map-accordion-link").click(function () {
            let id = $(this).attr('data-addressid');
            changeMarker(id);
        });

        $('a.map-accordion-link').first().click();

        map.fitBounds(bounds);
        for (let i = 0; i < Markers.length; i++) {
            Markers[i].setIcon(inactivePin);
        }
        $('#map-v3').show();


        function killButtons() {
            $('#map-v3 a, #map-v3 button').attr("tabindex", -1);
            $("#map-v3 [tabindex]").attr("tabindex", -1);
        }

        google.maps.event.addListenerOnce(map, 'idle', function(){
            setTimeout(killButtons, 500);
        });

        google.maps.event.addListener(map, 'idle', function(){
            setTimeout(killButtons, 500);
        });

        google.maps.event.addListener(map, "tilesloaded", function(){
            setTimeout(killButtons, 500);
        });

        window.locationSearchMapInitialized = true;

        function setUpLinkClicks(link) {
            let infoDiv;

            const latitude = $(link).attr('data-latitude');

            if (typeof latitude === typeof undefined || latitude === false) {
                infoDiv = $(link).parents('div.map-content');
            } else {
                infoDiv = link;
            }

            if ($(infoDiv).attr('data-latitude') !== "" && $(infoDiv).attr('data-longitude') !== "") {

                $(link).click(
                    function () {
                        // Change color of pin to red and all others back to black when clicked on location
                        for (var i = 0; i < Markers.length; i++) {
                            Markers[i].setIcon(inactivePin);
                        }

                        const index = parseInt($(infoDiv).attr('data-addressid').replace('address', '')) - 1;

                        Markers[index].setIcon(activePin);

                        $('.map-content').removeClass("active");
                        $(this).addClass("active");
                    }
                );
            }
        }

        function setUpLocations() {

            const locationDivs = $('div.map-content');

            for (let i = 0; i < locationDivs.length; i++) {

                const infoDiv = locationDivs[i];

                if ($(infoDiv).attr('data-latitude') !== "" && $(infoDiv).attr('data-longitude') !== "") {
                    const linkLat = $(infoDiv).attr('data-latitude');
                    const linkLng = $(infoDiv).attr('data-longitude');

                    locations.push({
                        location: new maps.LatLng(linkLat, linkLng),
                        dataLocationType: $(infoDiv).attr('data-locationType'),
                        dataAddressType: $(infoDiv).attr('data-addresstype'),
                        dataName: $(infoDiv).attr('data-title'),
                        dataSpecialties: $(infoDiv).attr('data-specialties'),
                        dataAddress: $(infoDiv).attr('data-address'),
                        dataPhone: $(infoDiv).attr('data-phonenumber'),
                        dataDirections: $(infoDiv).attr('data-geocodeaddress'),
                        dataLink: $(infoDiv).attr('data-detailurl')
                    });
                }
            }
        }


    });
}

