import $ from "jquery";

export default function () {

  function initCorpHealthForm() {

    var hidden = "informationboxhidden";
    var shown = "informationbox";

      $('#IsTreatmentForInjury').change(function () {
        if (IsCheckOn($('#IsTreatmentForInjury'))) {
          FlipMyDiv($('#TreatmentForInjuryDiv'), true);
        }
        else {
          FlipMyDiv($('#TreatmentForInjuryDiv'), false);
        }
      });

      $('#ddsIsDOTDrugScreen').change(function () {
        if (IsCheckOn($('#ddsIsDOTDrugScreen'))) {
          FlipMyDiv($('#DotDrugScreenDiv'), true);
        }
        else {
          FlipMyDiv($('#DotDrugScreenDiv'), false);
        }
      });

      $('#nddsIsNonDOTDrugScreen').change(function () {
        if (IsCheckOn($('#nddsIsNonDOTDrugScreen'))) {
          FlipMyDiv($('#NonDOTDSDiv'), true);
        }
        else {
          FlipMyDiv($('#NonDOTDSDiv'), false);
        }
      });

      $('#dbaIsDOTBreathAlcohol').change(function () {
        if (IsCheckOn($('#dbaIsDOTBreathAlcohol'))) {
          FlipMyDiv($('#DotBreathAlcoholDiv'), true);
        }
        else {
          FlipMyDiv($('#DotBreathAlcoholDiv'), false);
        }
      });

      $('#ndbaIsNonBreathAlcohol').change(function () {
        if (IsCheckOn($('#ndbaIsNonBreathAlcohol'))) {
          FlipMyDiv($('#NonDotBreathAlcoholDiv'), true);
        }
        else {
          FlipMyDiv($('#NonDotBreathAlcoholDiv'), false);
        }
      });

      $('#phyIsPhysical').change(function () {
        if (IsCheckOn($('#phyIsPhysical'))) {
          FlipMyDiv($('#PhysicalDiv'), true);
        }
        else {
          FlipMyDiv($('#PhysicalDiv'), false);
        }
      });

      $('#dphyIsDotPhysical').change(function () {
        if (IsCheckOn($('#dphyIsDotPhysical'))) {
          FlipMyDiv($('#DotPhysicalDiv'), true);
        }
        else {
          FlipMyDiv($('#DotPhysicalDiv'), false);
        }
      });

      $('#othrOther').change(function () {
        if (IsCheckOn($('#othrOther'))) {
          FlipMyDiv($('#OtherDiv'), true);
        }
        else {
          FlipMyDiv($('#OtherDiv'), false);
        }
      });

      $('#rsIsRespServices').change(function () {
        if (IsCheckOn($('#rsIsRespServices'))) {
          FlipMyDiv($('#RespSvcDiv'), true);
        }
        else {
          FlipMyDiv($('#RespSvcDiv'), false);
        }
      });

      $('#vtIsVacinAndTet').change(function () {
        if (IsCheckOn($('#vtIsVacinAndTet'))) {
          FlipMyDiv($('#VaccineDiv'), true);
        }
        else {
          FlipMyDiv($('#VaccineDiv'), false);
        }
      });

    function FlipMyDiv(divName, turnOn) {
      if (turnOn) {
        divName.removeClass(hidden);
        divName.addClass(shown);
      }
      else {
        divName.removeClass(shown);
        divName.addClass(hidden);
      }
    }

    function IsCheckOn(control) {
      if (control.prop('checked')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  initCorpHealthForm();
}
