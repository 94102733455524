//dependencies
import $ from "jquery";
const loadGoogleMapsApi = require('load-google-maps-api');
var Bloodhound = require("corejs-typeahead/dist/bloodhound.min.js");
var searchUrl = window.location.pathname;
export default function () {
  // Autocomplete
  function initGoogleAutocomplete() {
    // Options and API Key
    let options = {
      key: 'AIzaSyDGq2ZihZFIdCz99te0XxQBr9Ii8MLLh0k',
      libraries: ['places']
    };
    loadGoogleMapsApi(options).then(function (maps) {
      const google = window.google;
      window.googleMapsApi = maps;
      // Init function
      function initialize() {
        let input = document.getElementById('address');
        let autocomplete = new google.maps.places.Autocomplete(input);

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address and geo components.
        autocomplete.setFields(["formatted_address", "geometry"]);

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
          let place = autocomplete.getPlace();

          document.getElementById('hdnAddress').value = place.formatted_address;
          document.getElementById('address').value = place.formatted_address;
          document.getElementById('latitude').value = place.geometry.location.lat();
          document.getElementById('longitude').value = place.geometry.location.lng();
        });
      }
      google.maps.event.addDomListener(window, 'load', initialize);
    }).catch(function (error) {
      console.error(error);
      });

    $('#address').attr('autocomplete', 'nothing');  // force autocomplete to a value other than "off" or "on"
  }

  function updateQueryString(key, value, url) {
    if (!url) url = window.location.href;
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
      hash;
    if (re.test(url)) {
      if (typeof value !== 'undefined' && value !== null) {
        value = encodeURIComponent(value);
        return url.replace(re, '$1' + key + "=" + value + '$2$3');
      } else {
        hash = url.split('#');
        url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
        if (typeof hash[1] !== 'undefined' && hash[1] !== null)
          url += '#' + hash[1];
        return url;
      }
    } else {
      if (typeof value !== 'undefined' && value !== null) {
        value = encodeURIComponent(value);
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        hash = url.split('#');
        url = hash[0] + separator + key + '=' + value;
        if (typeof hash[1] !== 'undefined' && hash[1] !== null)
          url += '#' + hash[1];
        return url;
      } else
        return url;
    }
  }

  function initLocationSearchPagination() {
    $(".location-search-page").on("click", function (e) {
      e.preventDefault();
      var targetPage = $(this).data("page");
      if (targetPage === 1) targetPage = null; // clear page from querystring if 1
      var url = updateQueryString("Page", targetPage);
      url = updateQueryString('view', 'list', url);
      window.location = url;
    });
  }

  var locationSearchInput = $("#location-search-input");
  var specialtyLocationInput = $('#selectSpecialtyLocation');
  var selectDistance = $('#selectDistance');

  function locationSearch() {
    var url = createURLFromParams();
    window.location.href = url;
  }

  function createURLFromParams() {
    var url = searchUrl + window.location.search;
    //grab all the parameter values
    var paramsDict = {
      "q": locationSearchInput.val(),
      "longitude": $("#longitude").val(),
      "latitude": $("#latitude").val(),
      "address": $("#hdnAddress").val(),
      "specialty": $("#selectSpecialtyLocation").val(),
      "distance": $("#selectDistance").val(),
      "view": $('#hdnView').val()
    };
    //add the parameters to the query string
    for (var key in paramsDict) {
      if (paramsDict[key] != '' || paramsDict[key] != null) {
        url = updateQueryString(key, paramsDict[key], url);
      }
    }
    return url;
  }

  function clearURLParams() {
    var url = searchUrl + window.location.search;

    //grab all the parameter values
    var paramsDict = {
      "q": '',
      "longitude": '',
      "latitude": '',
      "address": '',
      "specialty": '',
      "distance": '',
      "sort": '',
      "page": '',
      "view": ''
    };

    //add the parameters to the query string
    for (var key in paramsDict) {
      if (paramsDict[key] != '' || paramsDict[key] != null) {
        url = updateQueryString(key, paramsDict[key], url);
      }
    }

    return url;
  }

  // the user just clicked "Use Current Address", so we set long and lat from the navigator.geolocation position
  // We also set some boilerplate text in the textbox indicating they're using their current address.
  function setCurrentLocation(position) {
    $('#longitude').val(position.coords.longitude);
    $('#latitude').val(position.coords.latitude);
    $('#hdnAddress').val($('#current-location-text').val());
    $('#address').val($('#current-location-text').val());
  }

  function showNoLocationError() {
    $('#location-finder-use-current-location').hide();
    $('#current-location-error').show();
  }

  function removeDomainNameFromUrl(url) {
    if (url && (url.indexOf('http') != -1 || url.indexOf('//') != -1)) {
      url = url.replace(/^.*\/\/[^\/]+/, '');
    }
    return url;
  }

  function initLocationSearch() {
    if ($('#location-search-input').length) {

      // Typeahead
      var specialties = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/LocationSearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            return response.Specialties;
          }
        }
      });
      var locations = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          wildcard: "%QUERY",
          url: "/api/sitecore/LocationSearchApi/GetTypeahead?query=%QUERY",
          transform: function transform(response) {
            return response.Locations;
          }
        }
      });
      locationSearchInput.typeahead({
        highlight: true,
        minLength: 3,
        hint: false,
        classNames: {
          suggestion: 'search-autocomplete-item',
          menu: 'search-autocomplete',
          dataset: 'search-autocomplete-listing'
        }
      },
        {
          name: "specialties",
          display: "Name",
          source: specialties,
          limit: 3,
          templates: {
            header:
              "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Specialties</h2></div>",
            suggestion: function suggestion(data) {
              return "<div><a href='" + updateQueryString("specialty", data.TaxonomyID, searchUrl) + "' class='search-autocomplete-link'>" + data.TaxonomyName + "</a></div>";
            }
          }
        },
        {
          name: "locations",
          display: "Name",
          source: locations,
          limit: 3,
          templates: {
            header:
              "<div class='search-autocomplete-heading'><h2 class='search-autocomplete-title'>Locations</h2></div>",
            suggestion: function suggestion(data) {
              return "<div><a href='" + removeDomainNameFromUrl(data.Url) + "' class='search-autocomplete-link'>" + data.LocationName + "</a></div>";
            }
          }
        });

      var $sortDropdown = $('#sortBy');
      $sortDropdown.on("change", function () {
        var selected = this.value;
        window.location = updateQueryString("sort", selected); //pass the filter to the query string
      });
      $('#btnSearch').on("click", function (e) {
        e.preventDefault();
        locationSearch();
      });
      var $clearBtn = $('#btnClearSearch');
      var $selectedItem;
      locationSearchInput.on('keyup', function (e) {
        // If keys pressed are up or down. Store ttcursor.
        if (e.which == 38 || e.which == 40) {
          $selectedItem = $(".twitter-typeahead").find(".tt-cursor a").get(0);
        }
        // If enter has been clicked, use the item in $selected item to perform the click.
        if (e.which == 13) {
          if ($selectedItem) {
            $selectedItem.click();
          }
        }
      });
      $clearBtn.on('click', function () {
        var url = clearURLParams();
        window.location.href = url;
      });
      locationSearchInput.keypress(function (e) {
        if (e.which === 13) {
          locationSearch();
        }
      });
     
      specialtyLocationInput.on('change', function () {
        locationSearch();
      });

      selectDistance.on('change', function () {
        locationSearch();
      });

      specialtyLocationInput.keypress(function (e) {
        if (e.which === 13) {
          locationSearch();
        }
      });

      // Use Current Location link should set long/lat in the hidden fields so that it's included in the search criteria
      $('#location-finder-use-current-location').on('click', function (e) {
        e.preventDefault();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(setCurrentLocation, showNoLocationError, { timeout: 10000 });
        } else {
          showNoLocationError();
        }
      });
      // The Address textbox ONLY allows selecting from the typeahead list (or clicking Use Current Location)
      // You can't simply type a zip code into the textbox and click enter.
      // Rather, you need to select the suggestion from the autocomplete list.
      // Thus, we always store the last-selected address in a hidden field so we can re-display it in case the user
      // decided not to select something from the autocomplete list and instead tries to type something in the text box and leaves the field.
      $('#address').on('blur', function () {
        $('#address').val($('#hdnAddress').val());
      });

      // disable hitting ENTER while in the address textbox
      $('#address').keypress(function (e) {
        if (e.which === 13) {
          $('#address').val('');
          $('#hdnAddress').val('');
          $('#longitude').val('');
          $('#latitude').val('');
        }
      });

      // force the default browser autocomplete (not google's autocomplete) to a value other than "off" or "on", because unfortunately "off" doesn't turn it off
      $('#address').on('focus', function () {
        $('#address').attr('autocomplete', 'nothing');  
      });


      var listViewTab = $('#listViewTab');
      var mapViewTab = $('#mapViewTab');
      
      listViewTab.on('click', function () {
        $('#hdnView').val('list');
      });

      mapViewTab.on('click', function () {
        $('#hdnView').val('map');
      });      
    }
  }

  initLocationSearch();
  initLocationSearchPagination();
  initGoogleAutocomplete();
}
