//dependencies
import $ from "jquery";
import debounce from "throttle-debounce/debounce";
import Cookies from "./js.cookie.js";

export default function () {
  let homepageVideoIsPaused = false;

  // I Am Dropdown Menu
  function initMainHeroExpand() {
    $(".i-am-wrapper").on("hide.bs.dropdown", function () {
      $(".hero-banner").show();
    });

    $(".i-am-wrapper").on("show.bs.dropdown", function () {
      $(".hero-banner").hide();

      if ($('.search-all').length) {
        $('.search-autocomplete').removeClass('active');
        $('.btn-clear').removeClass('active');
      }
    });
  }

  // Mobile I Am Dropdown Menu
  function initMobileMenu() {

    let $quickGuide = $('#mobileMenu');
    let $mobileCloseBtn = $('.mobile-menu-open');
    let $alertModule = $('.alert-module');

    //since we're now toggling, the header is not the right element - if the animation doesn't happen quickly enough, the mobile menu is part of the header, so calculating the height won't work here.
    //let $header = $('header');
    let $header = $('.i-am-wrapper');

    function alignQuickGuideUnderAlert() {
      let $menuDropdownHeight = Math.round($alertModule.height() + $header.height());
      $quickGuide.css({'top': $menuDropdownHeight + 'px'});
    }

    function alignQuickGuideUnderAlertandMobileMenu() {
      let $menuDropdownHeight = Math.round($header.height() + $alertModule.height());
      $quickGuide.css({'top': $menuDropdownHeight + 'px'});
    }

    function closeMenuWithAlert() {
      $quickGuide.removeAttr('style');
    }

    function alignQuickGuide() {
      let $alertOpen = $('.alert-module').hasClass('hide-alert');
      let $mobileMenuOpen = $('.navbar-collapse').hasClass('show');

      if (!$alertOpen && !$mobileMenuOpen) {
        alignQuickGuideUnderAlert();
      } else if (!$alertOpen && $mobileMenuOpen) {
        alignQuickGuideUnderAlertandMobileMenu();
      }
    }

    $('.mobile-trigger').on("click", function (e) {
      e.preventDefault();
      $mobileCloseBtn.toggleClass('show-close');

      //if the menu is open, close it since it obscures the quick guide
      if ($("#navbarNavDropdown").is(":visible")) {
        $("#navbarNavDropdown").collapse('hide');
      }

      // Check if Alert or Mobile Menu are open to position the Quick Guide underneath
      alignQuickGuide();

      $('.header-container').addClass('menuOpen');
      $quickGuide.toggleClass('show');

      if (!$('#mobileMenu').hasClass('show')) {
        closeMenuWithAlert();
      }
    });

    $("#navbarNavDropdown").on('show.bs.collapse', function () {
      //showing #navbarNavDropdown, close the quick guide;
      if ($(".mobile-menu-open").hasClass("show-close")) {
        $('.mobile-trigger').trigger("click");
      }
    });

    $("#collapseNav, #collapseExample").on('shown.bs.collapse', function () {
      $(".mobile-items > a").text("Close menu").addClass("opened");
    });

    $("#collapseNav, #collapseExample").on('hidden.bs.collapse', function () {
      $(".mobile-items > a").text("Open menu").removeClass("opened");
    });

    $('.nav-item a').on("click", function (e) {
      let $next = $(this).next();
      if ($next.length !== 0) {
        e.preventDefault();
        $next.toggleClass('show');
      }
    });

    $('.cancel-form').on('click', function (e) {
      e.preventDefault();
      window.history.go(-1);
    });

    $('.mobileMenuBack').on('click', function (e) {
      e.preventDefault();
      $(this).parent().toggleClass("show");
    });

    var $mobileSearch = $('#mobileSearch');
    $mobileSearch.keypress(function (e) {
      if (e.which === 13) {
        if ($mobileSearch.val() !== "") {
          window.location.href = "/search?q=" + $mobileSearch.val();
        }
      }
    });
  }

  function initHomepageVideo() {
    $(".hero-video-control").on("click", function () {
      if (!$(".fullscreen-bg__video").length) {
        return;
      }
      if ($(".fullscreen-bg__video").prop('paused') == false) {
        $(this).toggleClass("pause").attr("aria-label", "Play background video");
        $(".fullscreen-bg__video")[0].pause();
        homepageVideoIsPaused = true;
      } else {
        $(this).toggleClass("pause").attr("aria-label", "Pause background video");
        $(".fullscreen-bg__video")[0].play();
        homepageVideoIsPaused = false;
      }
    });

    function checkHomepageVideoPlayState() {
      if (!$(".fullscreen-bg__video").length) {
        return;
      }

      if (homepageVideoIsPaused) {
        //then pause the video cause it should NOT be playing
        $(".fullscreen-bg__video")[0].pause();
      }
    }

    $(window).on('orientationchange', function () {
      checkHomepageVideoPlayState();
    });

    $(window).resize(debounce(100, function () {
      checkHomepageVideoPlayState();
    }));

    if ($(window).width() < 992) {
      if ($('.fullscreen-bg__video').length) {

        $('.fullscreen-bg__video')[0].pause();
        $('.fullscreen-bg__video')[0].src = '';
        $('.fullscreen-bg__video').children('source').attr('src', '');
        $('.fullscreen-bg__video').remove().length = 0;
      }
    }

    $(window).resize(debounce(100, function () {
      if ($(window).width() < 992) {
        if ($('.fullscreen-bg__video').length) {

          $('.fullscreen-bg__video')[0].pause();
          $('.fullscreen-bg__video')[0].src = '';
          $('.fullscreen-bg__video').children('source').attr('src', '');
          $('.fullscreen-bg__video').remove().length = 0;
        }
      }
    }));
  }

  // Stop footer menu-items from collapsing above 767px
  function initPreventCollapse() {
    $('.footer-container .headline[data-toggle="collapse"]').click(function (e) {
      if ($(window).width() > 767) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  //setupSmoothScroll
  function initSmoothScroll() {
    var anchorLink = $(".anchor-link");
    if (anchorLink.length) {
      anchorLink.on("click", function (e) {
        var $offsetTarget = $($(this).attr("href"));
        var offset = $($(this).attr("href")).offset().top;
        $("html, body").animate({scrollTop: offset}, 500, function () {
          var $offsetSpacer = $(".header-container").height();
          $("html, body").animate({scrollTop: offset - $offsetSpacer}, 250);
          $offsetTarget.attr("tabindex", -1).focus();
        });
        e.preventDefault();
      });
    }
  }

  // Tabs inside dropdown
  function initTabsDropdown() {

    $(".i-am-wrapper .dropdown-menu a[data-toggle='tab']").click(function (e) {
      e.stopPropagation();
      $(this).tab('show');
    });

    $(".i-am-wrapper .dropdown-menu a[data-toggle='tab']").on('shown.bs.tab', function (e) {
      var newTarget = "#" + $(e.target).attr("aria-controls");
      $(newTarget).attr("tabindex", -1).focus();
    });

  }

  function initVideoTranscript() {
    $(".video-transcript-close a").click(function () {
      $(this).parents('.collapse').collapse('hide');
    });
  }

  // Add to Any
  function addToAny() {
    $('<script>var a2a_config = a2a_config || {}; a2a_config.templates = { email: { subject: "${title}", body: "Check out this website: ${link}"}};</' + 'script>').appendTo(document.body);

    var addtoanyScript = document.createElement('script');
    addtoanyScript.setAttribute('src', '//static.addtoany.com/menu/page.js');
    document.body.appendChild(addtoanyScript);
  }

  // Alert Module
  function closeAlert() {
    $('#btnCloseAlertPanel').on('click', function () {
      var $alertBox = $('.alert-module');
      $alertBox.addClass('hide-alert');
      $alertBox.attr('aria-hidden', 'true');
      let $header = $('header');
      $('.alert-module').parent().addClass('destroy-pin');

      if ($('#mobileMenu').hasClass('show')) {
        $('#mobileMenu').removeAttr('style');
        $('#mobileMenu').css({'top': $header.height() + 'px'});
      }

      var alerts = $('.alert-item');
      alerts.each(function () {
        var $this = $(this);
        var cookieId = $this.data("cookieid");

        if ($this.data("dismissible") === "True") {
          Cookies.set(cookieId, true, {expires: 365, path: '/'});
        } else {
          Cookies.set(cookieId, true, {expires: null, path: '/'}); // expiration = null means it's session based cookie
        }
      });
    });
  }

  // Tier Wide w/Image
  function checkCopy() {
    var $tierWideCopy = $('.tier-wide-image-copy');
    var $eventHeaderCopy = $('.event-header');
    var $locationHeaderCopy = $('.location-header');
    var $tierHeader = $('.tier-section-header');

    if ($tierWideCopy.length || $eventHeaderCopy.length || $locationHeaderCopy.length) {
      $tierHeader.addClass('tier-section-header-default');
    }

    if ($locationHeaderCopy.find('figure').length <= 0) {
      $tierHeader.addClass('no-image');
    }
  }

  function videoPlayer() {

    if ($('.video').length) {
      initVideoVersion1();
    }

    function initVideoVersion1() {

      var theProtocol = location.protocol;
      var theURL = theProtocol + "//players.brightcove.net";
      // Get a reference to the iframe element
      var iframeTag = document.querySelector('.video iframe');
      // Retrieve window object needed for postMessage
      var win = iframeTag.contentWindow;

      function playVideo() {
        // Post message passing 'playVideo' as the data
        win.postMessage("playVideo", theURL);
      }

      function pauseVideo() {
        // Post message passing 'pauseVideo' as the data
        win.postMessage("pauseVideo", theURL);
      }

      $('.video a.video-play').on('click', function (e) {

        var videoModuleContainer = $(this).parents('.video');
        videoModuleContainer.find('figure').fadeOut('slow');
        videoModuleContainer.find('.container').fadeOut('slow');
        videoModuleContainer.find('.close').fadeIn('slow');
        videoModuleContainer.find('.embed-responsive').css({'z-index': '9'});
        e.preventDefault();

        playVideo();
      });

      $('.video a.video-close').on('click', function (e) {

        var videoModuleContainer = $(this).parents('.video');
        videoModuleContainer.find('figure').fadeIn();
        videoModuleContainer.find('.container').fadeIn();
        videoModuleContainer.find('.close').fadeOut('slow');
        videoModuleContainer.find('.embed-responsive').css({'z-index': '-1'});
        e.preventDefault();

        pauseVideo();
      });
    }
  }

  function skipNav() {
    var $skip = $('.skip-nav');
    var $skipContent = $('#skipToContent');

    $skip.on('click', function () {
      $skipContent.focus();
    });
  }


  function disableAlphabetFilters() {
    var $disabledLetter = $('.filter-pagination-item.disabled');

    if ($disabledLetter.length) {
      var $disabledButton = $disabledLetter.children($('.filter-pagination-link'));

      //don't set the aria label to disabled. Setting the aria-disabled and/or the disabled field will be enough to tell ATs that the field is disabled.
      $disabledButton.attr('aria-disabled', 'true');
      $disabledButton.prop('disabled', true);
    }
  }

  function resultsFilteredLinks() {
    var $filteredLink = $('a.results-filter-link');

    if ($filteredLink.length) {

      //don't set the aria label to disabled. Setting the aria-disabled and/or the disabled field will be enough to tell ATs that the field is disabled.
      $filteredLink.attr('aria-current', 'false');
    }

    $filteredLink = $('a.results-filter-link.active');
    if ($filteredLink.length) {
      $filteredLink.attr('aria-current', 'true');
    }
  }

  function disabledLinks() {
    var $disabledLink = $('a.disabled');

    if ($disabledLink.length) {

      //don't set the aria label to disabled. Setting the aria-disabled and/or the disabled field will be enough to tell ATs that the field is disabled.
      $disabledLink.attr('aria-disabled', 'true');
    }
  }

  function ModifyPDFLinks() {
    var $homeContent = $('.home-content');
    if ($homeContent.length) {
      var $pdfLink = $homeContent.find('a[href$=".pdf"] i');
      if ($pdfLink.length) {
        $pdfLink.addClass("pdf-link");
        $pdfLink.each(function () {
          $(this).attr('aria-label', $(this).text() + ' PDF');
        });
      }
    }
  }

  function hideDuplicateVideoPlayButtons() {
    if ($("div.video .video-close")) {
      $("div.video .video-close").remove();
    }

    if ($("div.video .video-play")) {
      $("div.video .video-play").remove();
    }
  }

  hideDuplicateVideoPlayButtons();


  function stripTagsFromInaccessibleForm() {
    if ($("form.ao-form").length) {
      return;
    }

    $('form.ao-form input').removeAttr("tabindex");
    $('form.ao-form select').removeAttr("tabindex");
    $('form.ao-form textarea').removeAttr("tabindex");

  }

  stripTagsFromInaccessibleForm();

  // Get URL Parameter (with /IE Support)
  function getURLParameter(sParam) {
    let sPageURL = window.location.search.substring(1);
    let sURLVariables = sPageURL.split('&');
    for (let i = 0; i < sURLVariables.length; i++) {
      let sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1];
      }
    }
  }

  // Location tab view switcher
  function locationTabView() {

    // Variables
    let tabListView = $('#listViewTab');
    let tabMapView = $('#mapViewTab');
    let tabResponsiveBreakpoint = 992;

    let searchParam = getURLParameter('view');
    // console.log(getURLParameter('view'));

    // If list view is active in query string.
    if (searchParam === "list") {
      tabListView.tab('show');
      // console.log("show list view if query string is list");
    } else {
      // No pagination, do a responsive check
      if ($(window).width() < tabResponsiveBreakpoint) {
        tabListView.tab('show');
        // console.log("mobile, show list view");
      } else {
        tabMapView.tab('show');
        // console.log("desktop, show map view");
      }
    }
  }

  locationTabView();

  // Location tab view switcher on resize
  $(window).resize(function () {
    locationTabView();
  });

  //initRecentlyViewed();
  initMainHeroExpand();
  initMobileMenu();
  initHomepageVideo();
  initTabsDropdown();
  initPreventCollapse();
  initSmoothScroll();
  // initViewAction();
  initVideoTranscript();
  addToAny();
  closeAlert();
  checkCopy();
  videoPlayer();
  skipNav();
  disableAlphabetFilters();
  disabledLinks();
  resultsFilteredLinks();
  ModifyPDFLinks();

}

