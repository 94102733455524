//dependencies
import $ from "jquery";
import 'select2';

export default function () {

  function initSelectFields() {

    const $selectWrapper = $(".select-wrapper select");

    if ($selectWrapper.length) {

      // Select Wrapper
      $selectWrapper.select2({
        width: "100%",
        minimumResultsForSearch: 2
      });
      //$($selectWrapper).attr("tabindex", "0");

      $(".select2-selection").on("keydown", function (event) {
        if (event.which == 9 && event.shiftKey) {
          //console.log("shift tab in the selectwrapper!");
          var tabbableElements = $(document).find('a[href]:not(".close, .disabled"):visible, area[href], input:not([disabled]):visible, select:not([disabled]):visible, textarea:not([disabled]):visible, button:not([disabled]):visible, iframe:visible, [tabindex=0]:visible, [contentEditable=true]:visible').not("[tabindex=-1]");
          //console.log("tabbable elements?", tabbableElements);

          let thisElementIndex = -1;
          thisElementIndex = Array.from(tabbableElements).indexOf(document.activeElement);
          //console.log("How many tabbable elements?", tabbableElements.length);
          //console.log("thisElementIndex", thisElementIndex);
          //console.log("activeElement:", document.activeElement);
          if (thisElementIndex === 0) {
            // If this element is the first one in the tabbable elements, then we need to go back to the last element.
            event.preventDefault();
            tabbableElements[tabbableElements.length - 1].focus();
            //console.log("moving to:", tabbableElements[tabbableElements.length - 1]);
          }
          else if (thisElementIndex === tabbableElements.length - 1) {
            //if this is the last element in the tabbable list, then go to the first element in the list.
            event.preventDefault();
            //console.log("moving to:", tabbableElements[0]);
            tabbableElements[0].focus();
          }
          else {
            //otherwise, just move back one item and do your thing.
            event.preventDefault();
            //console.log("moving to:", tabbableElements[thisElementIndex - 1]);
            tabbableElements[thisElementIndex - 1].focus();
          }

        }
      });
    }
  }

  initSelectFields();
}
