import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import debounce from "throttle-debounce/debounce";

//import {TweenMax, TimelineMax} from 'gsap';

export function initHomeScroll() {

  let $viewHeight = $(window).height();
  let $viewWidth = $(window).width();

  if ($viewHeight > 730) {
    homeScroll();
  }  /*
  else {
    let $header = $('.home .header-container');
    $header.addClass('scroll-relative');
  } */

  if ($viewWidth > 750 && $('.alert-module').length) {
    pinAlert();
  }

  function pinAlert() {
    let $home = $('.home-content');
    let $alert = $('.alert-module');
    let $closeAlert = $('.closeAlert a');
    let controller = new ScrollMagic.Controller();

    if ($home.length) {
      console.log('homepage alert');
      $alert.addClass('pinned');

      let scene = new ScrollMagic.Scene({
        triggerElement: ".alert-module",
        triggerHook: 0
      })
        .setPin(".alert-module")
        .addTo(controller);

      $closeAlert.on('click', function () {
        console.log('clicked');
        $alert.removeClass('pinned');
        controller = controller.destroy(scene);
      });
    }
  }

  function homeScroll() {
    let $articlePage = $(".home-container .page-cover");
    if ($articlePage.length) {
      // create the scrollMagic controller
      let controller;
      controller = new ScrollMagic.Controller();

      // if ($.browser.mobile) {
      //   controller.destroy();
      // } else {
      let $homeHeroContainer = $(".home-hero-container");
      new ScrollMagic.Scene({
        triggerElement: ".home-hero-wrapper",
        triggerHook: 0,
        reverse: true
      })
        .setPin(".home-hero-wrapper")
        .addTo(controller);

      new ScrollMagic.Scene({
        triggerElement: ".home-hero-container",
        triggerHook: 0,
        duration: $homeHeroContainer.height(),
        reverse: true
      })
      //.setTween(TweenMax.to($homeHeroContainer, 1, {autoAlpha: 0.3}))
        .addTo(controller);

      if ($(".home-hero-wrapper video").length) {
        if ($(window).width() > 991) {
          $('video').get(0).play();
        }
        else {
          $('video').get(0).pause();
        }
      }
      // // on resize adjust pin spacer height
      $(window).resize(debounce(250, function () {
        let $pinSpacer = $(".section-hero .scrollmagic-pin-spacer");
        let $homeHeroWrapper = $(".home-hero-wrapper");
        $pinSpacer.css("min-height", $homeHeroWrapper.height());

        if ($(".home-hero-wrapper video").length) {
          if ($(window).width() > 991) {
            $('video').get(0).play();
          }
          else {
            $('video').get(0).pause();
            //console.log('pause resize');
          }
        }
      }));
    }
  }
}

export function initRightRailNav() {
  let anchorLink = $(".list-group-item-action");
  if (anchorLink.length) {
    anchorLink.on("click", function (e) {
      let $offsetTarget = $($(this).attr("href"));
      let offset = $($(this).attr("href")).offset().top;
      $("html, body").animate({scrollTop: offset}, 500, function () {
        $("html, body").animate({scrollTop: offset}, 250);
        $offsetTarget.attr("tabindex", -1).focus();
      });
      e.preventDefault();
    });
  }

  const controller = new ScrollMagic.Controller();

  function doScrollingMenu() {
    if ($(window).width() < 992) {
      controller.enabled(false);
      $('.scrollmagic-pin-spacer').css('padding-bottom','0').css('padding-top','0').css('min-height','initial');
    }
    else {
      new ScrollMagic.Scene({
        triggerElement: ".sidebar-menu",//$("[data-sticky]").find("a").eq(0).attr("href"),
        triggerHook: 0,
        offset: '-30px',
        duration: $("[data-sticky-container]").outerHeight() - $("[data-sticky]").outerHeight()    // the scene should last for a scroll distance of height of #mainContent
      })
        .setPin("[data-sticky]") // pins the element for the the scene's duration
        .addTo(controller); // assign the scene to the controller
    }

    $(window).resize(debounce(250, function () {
      if ($(window).width() < 992) {
        controller.enabled(false);
        $('.scrollmagic-pin-spacer').css('padding-bottom','0').css('padding-top','0').css('min-height','initial');
      }
      else {
        controller.enabled(true);
      }
      controller.update(true);
    }));
  }


  if ($('.sidebar-menu').length){
    doScrollingMenu();
  }
}


// Animate blocks
export function initAnimateBlocks() {

  let $mainContainer = $(".main-container");
  let $moduleContent = $(".four-column-cards .module-content");
  if ($mainContainer.length) {

    // create the scrollMagic controller
    let controller;
    controller = new ScrollMagic.Controller();

    // if ($.browser.mobile) {
    //   controller.destroy();
    // } else {
    $($moduleContent).each(function () {
      let $this = $(this);
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1
      })
        .on("enter", function () {
          $this.addClass("animated fadeInUp");
        })
        .addTo(controller);
    });
    // }
  }
}

export function initAnimateHeroBlock() {

  let $mainContainer = $(".main-container");
  let $moduleContent = $(".callout-text .row");
  if ($mainContainer.length) {

    // create the scrollMagic controller
    let controller;
    controller = new ScrollMagic.Controller();

    // if ($.browser.mobile) {
    //   controller.destroy();
    // } else {
    $($moduleContent).each(function () {
      let $this = $(this);
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1
      })
        .on("enter", function () {
          $this.addClass("animated fadeInUp");
        })
        .addTo(controller);
    });
    // }
  }
}

export function initAnimateHomeEvent() {

  let $mainContainer = $(".main-container");
  let $moduleContent = $(".home-carousel-news-events .custom-card ");
  if ($mainContainer.length) {

    // create the scrollMagic controller
    let controller;
    controller = new ScrollMagic.Controller();

    // if ($.browser.mobile) {
    //   controller.destroy();
    // } else {
    $($moduleContent).each(function () {
      let $this = $(this);
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1
      })
        .on("enter", function () {
          $this.addClass("animated fadeInUp");
        })
        .addTo(controller);
    });
    // }
  }
}

