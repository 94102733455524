
import $ from "jquery";

export default function () {

  /**********************
   * Constant Variables *
   **********************/
  var formClassName = '.email-subscription-form';
  /*  DEV SETTINGS
  var aidValue = '39815';
  var fidValue = 'd7a915d9-70e7-4249-886c-a209790d491f';
  var serverValue = 'cidev.actonsoftware.com';
  var formIdValue = 'fxb_092e78e8-8e86-43f8-9534-c5184a5dc055_c0c12286-9d9b-4e2b-978d-8a0b123c6137';
  var subscriptionFieldName = 'EmailSubscription';
  */
  var aidValue = '19609';
  var fidValue = '811342cb-5c9d-4e6d-9192-a335cfb44384';
  var serverValue = 'cid3.actonsoftware.com';
  var formIdValue = 'fxb_4c4ecfbe-c8f2-425e-ab3c-0dd256a26307_02ca1203-ee3e-412e-bc4c-e10df8279fa4';
  var subscriptionFieldName = 'Email Subscription';
  
  function updateValidationStyles() {
    $('.form-group').removeClass('error');
    $('.field-validation-error').parent().addClass('error');
  }

  /// ****** OBSOLETE:  For the LONG form.  We're now using the ActOn default embed rather than Sitecore Forms and the javascript submission.
  $(function () {
    var $form = $(formClassName);
    if ($form.length !== 0) {
      $form.on("submit", function (e) {
        updateValidationStyles();

        // Submit form to Act-On
        if ($('.input-validation-error').length === 0 && $('input[type=checkbox]:checked').length !== 0) {
          setTimeout(AoProcessForm(this), 0);
        }

        e.preventDefault();
      });
    }

    $('.form-group input[type=text]').blur(function () {
      updateValidationStyles();
    });

    /***********************************************************************************************************************************
     * Act-On Integration - JavaScript Triggered by Custom Event - Customized code from the link below                                 *
     * https://university.act-on.com/Product_Features/Content/Forms/Publishing_Forms/Posting_External_Forms_to_Act-On_with_Custom_Code *
     ***********************************************************************************************************************************/

    var aoProtocol = location.protocol;
    if (aoProtocol.indexOf('http') < 0) aoProtocol = 'http:';
    var aoCAP = {
      aid: aidValue,
      fid: fidValue,
      did: 'd-0001',
      server: serverValue,
      formId: formIdValue,
      protocol: aoProtocol
    };
    var aoArr = aoArr || []; aoArr.push(aoCAP);

    function AoProcessForm(formelement) {
      for (let AoI = 0; AoI < aoArr.length; AoI++) {
        if (aoArr[AoI].aid && aoArr[AoI].fid && aoArr[AoI].did && aoArr[AoI].server && aoArr[AoI].formId) {
          var d = document,
            thisFormId = formelement.id || '',
            bi = function (i) {
              return d.getElementById(i);
            },
            names = [],
            values = [],
            params = {},
            w = window,
            isLoaded = false,
            st = function (f, i) {
              w.setTimeout(f, i);
            },
            ce = function (t) {
              return d.createElement(t);
            },
            gid = function (p) {
              var j, i = 0,
                n = Math.random,
                r = [],
                c = '0123456789abcdef'.split('');
              for (; i < 16; i++) r[i] = c[(0 | n() * 16) & 0xf];
              j = p + r.join('');
              return bi(j) === null ? j : gid(p);
            },
            addInput = function (form, name, value) {
              var el = ce('input');
              el.name = name;
              el.value = value;
              form.appendChild(el);
            },
            idifr = gid('aoCapT');

          if (aoArr[AoI].formId === thisFormId) {
            var dTarget = ce('div');
            dTarget.style.display = 'none';
            d.body.appendChild(dTarget);
            dTarget.innerHTML = '<iframe name="' + idifr + '" id="' + idifr + '"><\/iframe>'; // generate iframe

            var dForm = ce('form'), idform = gid('aoCapD');
            dForm.id = idform;
            dForm.style.display = "none";
            dForm.method = 'POST';
            dForm.enctype = 'multipart/form-data';
            dForm.acceptCharset = 'UTF-8';
            dForm.target = idifr; // form targets iframe
            dForm.action = (aoCAP.protocol || w.location.protocol) + '//' + aoCAP.server + '/acton/forms/userSubmit.jsp';
            d.body.appendChild(dForm); // generate form

            // Get All Form Names and Values to Pass
            for (let i = 0; i < $('.form-control').length; i++) {
              let $el = $($('.form-control')[i]);
              let elementClass = $el.attr('class');
              let split = elementClass.split(' ');
              let firstClass = split[0];

              let name = firstClass;
              let value = $el.val();
              if (name !== null && name !== '') {
                names.push(name);
                values.push(value);
                console.log("Element name: " + name + " / Element value: " + value);
                params[name] = value;
              }
              addInput(dForm, name, value);
            }

            // Get Email Subscription Checkboxes
            let listValue = "";
            for (let i = 0; i < $('.form-group input[type=checkbox]').length; i++) {
              let el = $('.form-group input[type=checkbox]')[i];
              let $el = $(el);
              let elValue = $el.data("sc-field-name");

              if (el.checked) {
                if (listValue === "") {
                  listValue = elValue;
                }
                else {
                  listValue = listValue + "," + elValue;
                }
              }
            }
            //console.log(listValue);
            addInput(dForm, subscriptionFieldName, listValue);


            aoCAP.pid = 0;
            aoCAP.cuid = aoCAP.cuid || '';
            aoCAP.srcid = aoCAP.srcid || '';
            aoCAP.camp = aoCAP.camp || '';
            addInput(dForm, 'ao_a', aoArr[AoI].aid);
            addInput(dForm, 'ao_f', aoArr[AoI].fid);
            addInput(dForm, 'ao_d', aoArr[AoI].fid + ":" + aoArr[AoI].did);
            addInput(dForm, 'ao_p', 0);
            addInput(dForm, 'ao_jstzo', new Date().getTimezoneOffset());
            addInput(dForm, 'ao_form_neg_cap', '');
            addInput(dForm, 'ao_refurl', d.referrer);
            addInput(dForm, 'ao_cuid', aoCAP.cuid);
            addInput(dForm, 'ao_srcid', aoCAP.srcid);
            addInput(dForm, 'ao_camp', aoCAP.camp);
            bi(idform).submit();

            var dTargetFrame = bi(idifr);
            dTargetFrame.onload = function () {
              isLoaded = true;
            };
            var waitForSubmit = function () {
              this.count = "";
              if (!(isLoaded || dTargetFrame.readyState === "complete")) {
                st(waitForSubmit, 200);
                this.count++;
              } else if (this.count > 7) {
                return true;
              }
              else {
                d.body.removeChild(dForm);
                d.body.removeChild(dTarget);
              }
            };
            st(waitForSubmit, 100);
          }
        } else {
          console.log('aoCAP property missing');
        }
      }
    }
  });
}
